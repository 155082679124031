import { List, Divider, ListItemButton, ListItemAvatar, Avatar, ListItemText, styled } from '@mui/material';
import React from 'react';
import { User } from 'api-client/api';

const ResponsiveList = styled(List)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const VerticalUserList = (props: {
  users: User[];
  onItemClick?: (target: User) => void;
  selected?: User;
}): JSX.Element => {
  const { users, onItemClick, selected } = props;
  return (
    <ResponsiveList>
      {users.map((users, idx) => (
        <React.Fragment key={users.fullName}>
          {idx === 0 && <Divider variant="inset" component="li" />}
          <ListItemButton
            onClick={() => onItemClick && onItemClick(users)}
            sx={{
              paddingLeft: '0px',
            }}
            selected={selected && selected.email === users.email}
            alignItems="center"
          >
            <ListItemAvatar
              sx={{
                paddingRight: (theme) => theme.spacing(2),
              }}
            >
              <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main, width: '50px', height: '50px' }}>
                {users.initials}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={users.fullName} />
          </ListItemButton>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </ResponsiveList>
  );
};
