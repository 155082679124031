import { useParams } from 'react-router-dom';
import { BackofficeTasks } from '../BackofficeTasks/BackofficeTasks';
import { CustomerContact } from '../CustomerContact/CustomerContact';
import { FollowUp } from '../FollowUp/FollowUp';
import { MarketingProfile } from '../MarketingProfile/MarketingProfile';
import { SalesInformation } from '../SalesInformation/SalesInformation';
import { XimeaContact } from '../XimeaContact/XimeaContact';

export const ProcessRoot = (): JSX.Element => {
  const { step } = useParams<{ step?: string }>();
  switch (step) {
    case '1':
      return <XimeaContact />;
    case '2':
      return <CustomerContact />;
    case '3':
      return <MarketingProfile />;

    case '4':
      return <SalesInformation />;

    case '5':
      return <BackofficeTasks />;
    case '6':
      return <FollowUp />;

    default:
      return <>Not Found</>;
  }
};
