import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { appConfigSlice } from 'modules/common/core/appConfigSlice';
import { leadRecordingSlice } from 'modules/recordLead/core/leadRecordingSlice';
export const store = configureStore({
  reducer: {
    leadRecording: leadRecordingSlice.reducer,
    appConfig: appConfigSlice.reducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
