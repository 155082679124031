/**
 *
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ErrorMessage
   */
  type: ErrorMessage.TypeEnum;
}

/**
 * @export
 * @namespace ErrorMessage
 */
export namespace ErrorMessage {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    Error = 'error',
    Warning = 'warning',
  }
}
