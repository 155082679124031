import { Grid, styled } from '@mui/material';

export const ResponsiveGridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { display: 'flex', justifyContent: 'center' },
  [theme.breakpoints.down('sm')]: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    paddingBottom: theme.spacing(2),
  },
}));
