import { IConferenceDataService } from 'modules/recordLead/core/interfaces';
import { ConferenceParticipantData } from 'api-client/api';
import vCard from 'vcf';
import { IMetadataService } from 'modules/common/core/interfaces';

export class DefaultDataService implements IConferenceDataService {
  /**
   *
   */
  constructor(private metadataService: IMetadataService) {}

  fetchConferenceParticipantData(input: string): Promise<ConferenceParticipantData | undefined> {
    try {
      const card = new vCard().parse(input.replace('\n', '\r\n'));
      if (!card.data) {
        return Promise.resolve(undefined);
      }

      const address = this.getAddress(card);
      const countries = this.metadataService.getCountries();
      const country = countries.find((x) => x.name === address?.country || x.iso2AlphaCode === address?.country);
      return Promise.resolve({
        addressText: Object.values(address ?? {})
          .filter((x) => !!x)
          .join(', '),
        city: address?.city,
        country: country?.name,
        countryCode: country?.iso2AlphaCode,
        state: address?.state,
        street: address?.street,
        zip: address?.zip,
        company: this.getCompany(card),
        email: this.getEmail(card),
        lastName: this.getLastName(card),
        firstName: this.getFirstName(card),
        jobTitle: this.getTitle(card),
        phone: this.getPhone(card),
        website: this.getWebsite(card),
      });
    } catch {
      return Promise.resolve(undefined);
    }
  }
  private getWebsite(card: vCard): string {
    const property = card.get('url');
    if (!property) {
      return '';
    }

    return Array.isArray(property) ? property[0].valueOf() : property.valueOf();
  }

  private getPhone(card: vCard): string {
    const property = card.data.tel;
    if (!property) {
      return '';
    }

    let phoneValue = '';
    if (!Array.isArray(property)) {
      phoneValue = property.valueOf() ?? '';
    } else {
      let phone = property.find((x) => x.is('work'));
      if (!phone) {
        phone = property[0];
      }
      phoneValue = phone?.valueOf() ?? '';
    }

    return phoneValue.replace('tel:', '');
  }

  private getTitle(card: vCard): string {
    const property = card.data.title;
    if (!property) {
      return '';
    }
    if (!Array.isArray(property)) {
      return property?.valueOf() ?? '';
    } else {
      return property[0].valueOf();
    }
  }

  private getFirstName(card: vCard): string {
    const property = card.data.n;
    if (!property) {
      return '';
    }
    const value = (Array.isArray(property) ? property[0].valueOf() : property.valueOf()) ?? '';
    const parts = value.split(';');
    if (parts.length > 1) {
      return parts[1];
    }

    return '';
  }

  private getLastName(card: vCard): string {
    const property = card.data.n;
    if (!property) {
      return '';
    }
    if (!Array.isArray(property)) {
      return (property.valueOf() ?? '').split(';')[0];
    } else {
      return (property[0].valueOf() ?? '').split(';')[0];
    }
  }
  private getEmail(card: vCard): string {
    const property = card.data.email;
    if (!property) {
      return '';
    }
    let emailValue = '';
    if (!Array.isArray(property)) {
      emailValue = property.valueOf();
    } else {
      let email = property.find((x) => x.is('work'));
      if (!email) {
        email = property[0];
      }
      emailValue = email?.valueOf() ?? '';
    }
    return emailValue;
  }
  private getCompany(card: vCard): string {
    let data = '';
    if (!Array.isArray(card.data.org)) {
      data = card.data.org.valueOf();
    } else {
      data = card.data.org[0].valueOf();
    }

    return (data ?? '').split(';')[0];
  }
  private getAddress(card: vCard):
    | {
        street: string;
        city: string;
        state: string;
        zip: string;
        country: string;
      }
    | undefined {
    const property = card.data.adr;
    if (!property) {
      return undefined;
    }

    let addressValue = '';
    if (!Array.isArray(property)) {
      addressValue = property.valueOf();
    } else {
      let address = property.find((x) => x.is('work'));
      if (!address) {
        address = property[0];
      }
      addressValue = address?.valueOf() ?? '';
    }

    const addressParts = addressValue.split(';');
    if (addressParts.length < 7) {
      return undefined;
    }
    return {
      street: addressParts
        .slice(0, 3)
        .filter((x) => !!x?.length)
        .join(', '),
      city: addressParts[3],
      state: addressParts[4],
      zip: addressParts[5],
      country: addressParts[6],
    };
  }
}
