import { Feature } from 'flagged';

import { CustomerContactBadgeScanning } from './InputOptions/CustomerContactBadgeScanning';
import { CustomerContactManualEntry } from './InputOptions/CustomerContactManualEntry';

export const CustomerContact = (): JSX.Element => {
  return (
    <Feature name="expoBadgeScanningEnabled">
      {(hasFeature) =>
        hasFeature ? (
          <CustomerContactBadgeScanning></CustomerContactBadgeScanning>
        ) : (
          <CustomerContactManualEntry></CustomerContactManualEntry>
        )
      }
    </Feature>
  );
};
