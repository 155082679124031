import { useSelector } from 'react-redux';
import { AppState, useAppDispatch } from 'store/store';
import { leadRecordingSlice } from 'modules/recordLead/core';
import { CheckableList } from 'modules/common/components';

export const IndustryList = (): JSX.Element => {
  const industries = useSelector((state: AppState) => state.leadRecording.customerIndustries);
  const customIndustry = useSelector((state: AppState) => state.leadRecording.customIndustry);
  const dispatch = useAppDispatch();
  return (
    <CheckableList
      options={industries}
      title="Customer industry"
      onChange={(key, value) => dispatch(leadRecordingSlice.actions.setIndustries({ ...industries, [key]: value }))}
      searchable={true}
      extensionConfig={{
        currentExtensionValue: customIndustry,
        onExtensionChange: (value) => dispatch(leadRecordingSlice.actions.setCustomIndustry(value)),
      }}
    ></CheckableList>
  );
};
