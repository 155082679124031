import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { AppState, store, useAppDispatch } from 'store/store';
import { FlagsProvider } from 'flagged';
import { Features } from 'modules/common/core/types';
import { ConfirmProvider, WarningSnackbar } from 'modules/common/components';
import { LandingPage, DraftLeads, RecordLead } from 'modules';
import { ServicesProvider } from 'modules/common/context';
import { useCallback, useEffect } from 'react';
import { leadRecordingSlice } from 'modules/recordLead/core';
import Login, { useToken } from 'modules/login/components/Login';

declare module '@mui/material/styles' {
  interface Palette {
    navigation: Palette['primary'];
  }
  interface PaletteOptions {
    navigation?: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#EC6608',
      dark: '#EC6608',
    },
    secondary: {
      main: '#F0DABD',
      dark: '#F0DABD',
    },
    background: {
      default: '#252525',
      paper: '#3d3d3d',
    },
    navigation: {
      main: '#333333',
    },
  },

  typography: {
    h1: {
      fontSize: '3.5rem',
    },
    h2: {
      fontSize: '2.5rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    h6: {
      fontWeight: 'normal',
    },
  },
});
const App = (): JSX.Element => {
  const { token, setToken } = useToken();

  if (!token) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div id="LogIn">
          <Login setToken={setToken} />
        </div>
      </ThemeProvider>
    );
  }
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div id="App">
          <ConfirmProvider>
            <ReduxProvider store={store}>
              <ServicesProvider>
                <WithFeatureFlags></WithFeatureFlags>
              </ServicesProvider>
            </ReduxProvider>
          </ConfirmProvider>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const WithFeatureFlags = () => {
  const flags = useSelector<AppState>((state) => state.appConfig.features) as Features;

  return (
    <FlagsProvider features={flags}>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route
          path="/lead-recording/:step"
          element={
            <NotReloadable>
              <RecordLead />
            </NotReloadable>
          }
        ></Route>
        <Route path="/draft-leads" element={<DraftLeads />}></Route>
      </Routes>
      <WarningSnackbar></WarningSnackbar>
    </FlagsProvider>
  );
};

const NotReloadable = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const preventRefresh = useCallback(async (e: BeforeUnloadEvent) => {
    dispatch(leadRecordingSlice.actions.reset());
    e.preventDefault();
    return;
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', preventRefresh);

    return () => {
      window.removeEventListener('beforeunload', preventRefresh);
    };
  }, [preventRefresh]);
  return <>{children}</>;
};

export default App;
