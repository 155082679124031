import { List } from '@mui/material';
import { Lead } from 'modules/common/core/db';
import { LeadListItem } from './LeadListItem';

export const DraftLeadsList = ({ leads }: { leads: Lead[] }): JSX.Element => {
  return (
    <List sx={{ flexGrow: 1, overflowY: 'auto', padding: (theme) => theme.spacing(0, 1) }}>
      {[...leads]
        .sort((a, b) => {
          return a.lastModifiedOn > b.lastModifiedOn ? -1 : 1;
        })
        .map((lead) => (
          <LeadListItem key={lead.id} lead={lead} />
        ))}
    </List>
  );
};
