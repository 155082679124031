import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { Box, CssBaseline, styled, SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material';
import { Global } from '@emotion/react';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState, useAppSelector } from 'store/store';
import { targetedOrganizationSelector } from 'modules/recordLead/core/leadRecordingSlice';

type StepData = {
  title: string;
  id: string;
  dataSelector: (state: AppState) => string | undefined;
};

function getSteps(): StepData[] {
  return [
    {
      title: 'Our contact',
      id: 'XimeaContact',
      dataSelector: (state) => `Our contact: ${state.leadRecording.ximeaContact?.fullName}`,
    },
    {
      title: 'Prospect information',
      id: 'CustomerContact',
      dataSelector: (state) =>
        `Customer: ${state.leadRecording.leadCustomer?.salutation ?? ''}${
          state.leadRecording.leadCustomer?.fullName ?? ''
        }`,
    },
    {
      title: 'Marketing profile',
      id: 'MarketingProfile',
      dataSelector: (state) =>
        `Marketing Profile: ${state.leadRecording.leadCustomer?.salutation ?? ''}${
          state.leadRecording.leadCustomer?.fullName ?? ''
        }`,
    },
    {
      title: 'Sales notes',
      id: 'SalesInfo',
      dataSelector: (state) =>
        `Sales notes: ${state.leadRecording.leadCustomer?.salutation ?? ''}${
          state.leadRecording.leadCustomer?.fullName ?? ''
        }`,
    },
    {
      title: 'Backoffice tasks',
      id: 'MarketingMaterials',
      dataSelector: (state) =>
        `Backoffice tasks: ${state.leadRecording.leadCustomer?.salutation ?? ''}${
          state.leadRecording.leadCustomer?.fullName ?? ''
        }`,
    },

    {
      title: 'Follow up',
      id: 'FollowUp',
      dataSelector: (state) =>
        `Follow up: ${state.leadRecording.leadCustomer?.salutation ?? ''}${
          state.leadRecording.leadCustomer?.fullName ?? ''
        }`,
    },
  ];
}

const StepperWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 2, 0),
  backgroundColor: theme.palette.navigation.main,
  backgroundImage: 'none',
  zIndex: 1000,
}));

const StepperDisplayLargeScreen = () => {
  const { step } = useParams<{ step?: string }>();
  const stepIdx = Number(step) - 1 || 0;
  const steps = getSteps();
  return (
    <StepperWrapper>
      <Stepper
        sx={{
          paddingBottom: (theme) => theme.spacing(1),
          overflowX: 'auto',
        }}
        activeStep={stepIdx}
        alternativeLabel
      >
        {steps.map((step) => {
          return (
            <Step key={step.id}>
              <StepLabel>
                <StepLabelText stepData={step} />
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </StepperWrapper>
  );
};

const StepperDisplaySmallScreen = () => {
  return <SwipeableEdgeDrawer></SwipeableEdgeDrawer>;
};

export const StepperDisplay = (): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (isSmallScreen) {
    return <StepperDisplaySmallScreen></StepperDisplaySmallScreen>;
  }
  return <StepperDisplayLargeScreen></StepperDisplayLargeScreen>;
};

const FlexSpan = styled('span')({
  display: 'flex',
  flexDirection: 'column',
});

type StepLabelTextProps = {
  stepData: StepData;
  orientation?: 'horizontal' | 'vertical';
};

const StepLabelText = (props: StepLabelTextProps) => {
  const { stepData } = props;
  const data = useSelector<AppState>((state: AppState) => stepData.dataSelector(state)) as string | undefined;
  const targetedOrganization = useAppSelector(targetedOrganizationSelector);
  const stepTitle = targetedOrganization ? `${stepData.title} for ${targetedOrganization}` : stepData.title;
  if (data) {
    return (
      <FlexSpan
        style={{
          alignItems: props.orientation === 'horizontal' ? 'center' : 'left',
        }}
      >
        {data}
        <Typography component="span" variant="caption" color="GrayText">
          {stepTitle}
        </Typography>
      </FlexSpan>
    );
  }

  return <>{stepTitle}</>;
};

const drawerBleeding = 56;

const Root = styled('div')({
  height: `${drawerBleeding}px`,
});

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.navigation.main,
}));

const Puller = styled(Box)({
  width: 30,
  height: 6,
  backgroundColor: grey[100],
  borderRadius: 3,
  position: 'absolute',
  bottom: 8,
  left: 'calc(50% - 15px)',
});

export default function SwipeableEdgeDrawer(): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const { step } = useParams<{ step?: string }>();
  const stepIdx = Number(step) - 1 || 0;
  const steps = getSteps();
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const targetedOrganization = useAppSelector(targetedOrganizationSelector);
  const stepTitle = targetedOrganization
    ? `${steps[stepIdx].title} for ${targetedOrganization} (${step}/${steps.length})`
    : `${steps[stepIdx].title} (${step}/${steps.length})`;

  return (
    <div>
      <Root>
        <CssBaseline />
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              maxHeight: `calc(100% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
          }}
        />

        <SwipeableDrawer
          anchor="top"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox
            sx={{
              px: 2,
              py: 2,
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Stepper activeStep={stepIdx} orientation="vertical">
              {steps.map((step) => (
                <Step key={step.title}>
                  <StepLabel>
                    <StepLabelText stepData={step} orientation="vertical" />
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </StyledBox>
          <StyledBox
            sx={{
              position: 'absolute',
              visibility: 'visible',
              right: 0,
              left: 0,
              bottom: -drawerBleeding,
            }}
          >
            <Typography sx={{ p: 2, color: 'text.secondary' }}>{stepTitle}</Typography>
            <Puller />
          </StyledBox>
        </SwipeableDrawer>
      </Root>
    </div>
  );
}
