import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DefaultApi } from 'api-client/api';
import { Box, Button, TextField } from '@mui/material';
import XimeaLogo from '../../../assets/logo.png';
import './Login.css';

export function useToken(): {
  setToken: (token: string) => void;
  token: string;
} {
  const getToken = () => {
    return localStorage.getItem('token') as string;
  };
  const [token, setToken] = useState(getToken());

  const saveToken = (token: string) => {
    localStorage.setItem('token', token);
    setToken(token);
  };

  return {
    setToken: saveToken,
    token,
  };
}

function Login({ setToken }: { setToken: (token: string) => void }): JSX.Element {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await new DefaultApi().getToken({ password: password });
      const token = response.token;
      console.log(token);
      token != null && token != '' ? setToken(token) : setError('Login failed. Retrieved token is empty.');
    } catch (e) {
      setError('Login failed. Invalid password.');
    }
  };

  React.useEffect(() => {
    if (password != null && password != '') {
      setError('');
    }
  }, [password]);

  return (
    <div className="login-wrapper">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
        margin="Auto"
        component="form"
        sx={{ width: '26ch' }}
      >
        <img
          src={XimeaLogo}
          className="logo"
          style={{ marginTop: '3em', marginBottom: '2em', height: '10em', width: '20em' }}
          alt="Image"
        />
        <h1>Lead Recording</h1>
        <h3>Sign In</h3>
        <TextField
          id="outlined-basic"
          label="Password"
          variant="outlined"
          type="password"
          style={{ marginBottom: '1em' }}
          sx={{ width: '100%' }}
          value={password}
          error={error != ''}
          onChange={(e) => setPassword(e.target.value)}
          helperText={error != '' ? error : ' '}
          FormHelperTextProps={{ sx: { color: 'red' }, onClick: () => setError('') }}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ borderRadius: 28, width: '100%' }}
          style={{ marginBottom: '2em' }}
          onClick={handleSubmit}
        >
          Login
        </Button>
      </Box>
    </div>
  );
}
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
