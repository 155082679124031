import {
  Checkbox,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { SimpleCheckboxOptions } from 'modules/common/core/types';

type CheckboxListProps = {
  title: string;
  options: SimpleCheckboxOptions;
  onChange: (key: string, value: boolean) => void;
  searchable?: boolean;
  extensionConfig?: {
    currentExtensionValue?: string;
    onExtensionChange: (val: string) => void;
  };
};

const ListContainer = styled('div')({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
});

const EmptySearch = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: '0',
  },
  height: '48px',
}));

const ListComponentContainer = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const CheckableList = ({
  options,
  title,
  onChange,
  extensionConfig,
  searchable,
}: CheckboxListProps): JSX.Element => {
  const [search, setSearch] = React.useState<string>('');
  return (
    <ListContainer>
      <ListComponentContainer>
        {title && (
          <Typography
            variant="h5"
            color="primary"
            sx={{
              padding: (theme) => theme.spacing(2, 0),
            }}
          >
            {title}
          </Typography>
        )}
      </ListComponentContainer>

      {searchable ? <SearchBox value={search} onValueChanged={setSearch} /> : <EmptySearch></EmptySearch>}

      <List
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          margin: (theme) => theme.spacing(1, 0),
        }}
      >
        {Object.keys(options).map((key) => {
          if (!searchable || !search || (key.toLowerCase().includes(search.toLowerCase()) && !key.startsWith('#'))) {
            return (
              <OptionDisplay
                key={key}
                checkboxKey={key}
                value={options[key]}
                onSelected={(val: boolean) => onChange(key, val)}
              ></OptionDisplay>
            );
          }
          return <React.Fragment key={key}></React.Fragment>;
        })}
      </List>

      {extensionConfig && (
        <OtherInput value={extensionConfig.currentExtensionValue} onBlur={extensionConfig.onExtensionChange} />
      )}
    </ListContainer>
  );
};

/**
 *
 * @param value In the format #valueKey_value
 * @returns
 */
const OptionDisplay = ({
  value,
  checkboxKey,
  onSelected,
}: {
  value?: boolean;
  checkboxKey: string;
  onSelected?: (val: boolean) => void;
}) => {
  if (value === undefined || value === null) {
    value = false;
  }
  return (
    <ListItem key={checkboxKey} disablePadding>
      <ListItemButton
        role={undefined}
        onClick={() => {
          onSelected && onSelected(!value);
        }}
        dense
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={value}
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': `checkbox-list-label-${checkboxKey}` }}
          />
        </ListItemIcon>
        <ListItemText id={`checkbox-list-label-${checkboxKey}`} primary={checkboxKey} />
      </ListItemButton>
    </ListItem>
  );
};

const StyledTextBox = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  width: '240px',
}));

const SearchBox = ({ value, onValueChanged }: { value: string; onValueChanged: (val: string) => void }) => {
  return (
    <StyledTextBox
      label="Search"
      value={value}
      size="small"
      onChange={(e) => {
        onValueChanged(e.target.value);
      }}
      InputProps={{
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton aria-label="delete" size="small" onClick={() => onValueChanged('')}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        ) : (
          <InputAdornment position="end">
            <SearchIcon></SearchIcon>
          </InputAdornment>
        ),
      }}
      variant="filled"
    />
  );
};

const OtherInput = ({ value, onBlur }: { value?: string; onBlur: (val: string) => void }) => {
  const [val, setVal] = React.useState<string | undefined>(value);
  const onDeleteClick = () => {
    setVal('');
    onBlur('');
  };
  function onInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    onBlur(e.target.value);
  }
  return (
    <StyledTextBox
      size="small"
      label="Other"
      value={val}
      onChange={(e) => {
        setVal(e.target.value);
      }}
      onBlur={onInputBlur}
      variant="filled"
      InputProps={{
        endAdornment: val ? (
          <InputAdornment position="end">
            <IconButton aria-label="delete" size="small" onClick={onDeleteClick}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        ) : (
          <></>
        ),
      }}
    />
  );
};
