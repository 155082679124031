import { useContext } from 'react';
import { ServicesContext, ServicesState } from '../../common/context/ServicesContext/ServicesContext';

/**
 * Exposes the services registered for this application.
 * @returns An object containing all the services that are available in the application
 */

export const useServices: () => ServicesState = () => {
  const ctx = useContext(ServicesContext);
  if (ctx == null) {
    throw new Error(
      'Unable to access ServicesContext. Please wrap the component using this inside of a ServicesProvider'
    );
  }
  return ctx;
};
