import { styled } from '@mui/material';
import { FunctionComponent } from 'react';

import StepperDisplay from '../StepperDisplay/StepperDisplay';
import { NavigationProps, XimBottomNavigation } from 'modules/common/components';

const ContentContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  width: '100%',
  overflowY: 'auto',
}));

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});

export const ProcessStepPage: FunctionComponent<NavigationProps> = (props) => {
  return (
    <Content>
      <StepperDisplay></StepperDisplay>
      <ContentContainer>{props.children}</ContentContainer>
      <XimBottomNavigation {...props}></XimBottomNavigation>
    </Content>
  );
};
