import * as React from 'react';
import {
  ConfirmOptions,
  UserChoice,
  ConfirmContext,
} from '../components/dialogs/ConfirmationDialog/ConfirmationDialog';

export const useConfirm: () => (options?: ConfirmOptions) => Promise<UserChoice> = () => {
  const confirm = React.useContext(ConfirmContext);
  return confirm;
};
