import { Avatar, Button, Grid, Typography } from '@mui/material';
import { User } from 'api-client/api';
import { CenteredContent } from 'modules/common/components';

export const HorizontalUserList = ({
  users,
  onItemClick,
  selected,
}: {
  users: User[];
  selected?: User | undefined;
  onItemClick?: (target: User) => void;
}): JSX.Element => {
  return (
    <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
      {users.map((user, idx) => (
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={1}
          key={`${user.fullName} + ${idx}`}
          sx={{ justifyContent: 'center', display: 'flex' }}
        >
          <UserAvatar
            isSelected={!!selected && selected.fullName === user.fullName}
            user={user}
            onItemClick={onItemClick}
          ></UserAvatar>
        </Grid>
      ))}
    </Grid>
  );
};

const UserAvatar = ({
  user,
  onItemClick,
  isSelected,
}: {
  user: User;
  onItemClick?: (target: User) => void;
  isSelected: boolean;
}) => {
  return (
    <Button
      onClick={() => {
        onItemClick && onItemClick(user);
      }}
      variant={isSelected ? 'outlined' : 'text'}
    >
      <CenteredContent>
        <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main, width: 56, height: 56 }}>{user.initials}</Avatar>
        <Typography sx={{ color: (theme) => theme.palette.text.primary, marginTop: (theme) => theme.spacing(1) }}>
          {user.fullName}
        </Typography>
      </CenteredContent>
    </Button>
  );
};
