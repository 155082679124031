import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { BadgeScanning } from './BadgeScanning';
import { CustomerDataInputChoice } from '../CustomerDataInputChoice';
import { IndustryList } from '../IndustryList';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import BadgeIcon from '@mui/icons-material/Badge';
import { Feature } from 'flagged';
import { ProcessStepPage } from '../../ProcessStepPage/ProcessStepPage';
import { CenteredContent } from 'modules/common/components';
import { contactHasData } from 'modules/recordLead/utils/DataHandler';

export const CustomerContactBadgeScanning = (): JSX.Element => {
  const participantId = useSelector<AppState, string | undefined>(
    (state) => state.leadRecording.conferenceParticipantId
  );
  const leadCustomer = useSelector((state: AppState) => state.leadRecording.leadCustomer);

  return (
    <ProcessStepPage navigateNextDisabled={!participantId && !contactHasData(leadCustomer)}>
      <CenteredContent>
        <Typography variant="h6">Scan the customer badge</Typography>
      </CenteredContent>
      <Grid container spacing={2} justifyContent="center" sx={{ height: 'calc(100% - 16px)' }}>
        <Grid item xs={12} md={4}>
          <BadgeScanning></BadgeScanning>

          <Feature name="expoBadgeScanningAvailable">
            <CustomerDataInputChoice></CustomerDataInputChoice>
          </Feature>
        </Grid>
        {(participantId || contactHasData(leadCustomer)) && (
          <Grid item xs={12} md={2}>
            <ParticipantDetails participantId={participantId} />
          </Grid>
        )}
        <Grid sx={{ height: '100%' }} item xs={12} md={2}>
          <IndustryList />
        </Grid>
      </Grid>
    </ProcessStepPage>
  );
};

export const ParticipantDetails = (props: { participantId: string | undefined }): JSX.Element => {
  const loading = useSelector<AppState, boolean | undefined>((state) => state.leadRecording.leadCustomer?.loading);
  const leadCustomer = useSelector((state: AppState) => state.leadRecording.leadCustomer);
  return (
    <>
      <Typography
        variant="h5"
        color="primary"
        sx={{
          padding: (theme) => theme.spacing(2, 2, 1),
        }}
      >
        Customer data
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <BadgeIcon></BadgeIcon>
          </ListItemIcon>
          <ListItemText primary={props.participantId ?? '--'} secondary="Participant No." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={leadCustomer?.fullName} secondary="Name" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary={leadCustomer?.companyName} secondary="Company" />
        </ListItem>
      </List>
      {loading && (
        <Typography variant="body1" color="GrayText">
          Fetching data from API. Please wait...
        </Typography>
      )}
    </>
  );
};
