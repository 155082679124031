import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Paper, BottomNavigationAction, BottomNavigation, styled, Backdrop, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { UrlHandler } from 'modules/common/utils/UrlHandler';

import { AppState, useAppDispatch } from 'store/store';
import { leadRecordingSlice, storeAndReset } from 'modules/recordLead/core/leadRecordingSlice';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import HomeIcon from '@mui/icons-material/Home';
import { db } from 'modules/common/core';
import { useSelector } from 'react-redux';
import { useConfirm } from 'modules/common';
import { LeadRecordingState } from 'modules/recordLead/core/sliceTypes';

const XimBottomNavigationItem = styled(BottomNavigationAction)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&.disabled': {
    color: theme.palette.text.disabled,
  },
}));

export interface NavigationProps {
  reset?: () => void;
  onNavigateNext?: () => void;
  navigateNextDisabled?: boolean;
  onNavigatePrevious?: () => void;
  navigatePreviousDisabled?: boolean;
  children?: React.ReactNode;
  finalStepConfig?: {
    label: string;
    action: () => Promise<void>;
  };
}

export const XimBottomNavigation = ({
  reset,
  onNavigateNext,
  onNavigatePrevious,
  navigateNextDisabled,
  navigatePreviousDisabled,
  finalStepConfig,
}: NavigationProps): JSX.Element => {
  const navigation = useNavigate();
  const { step } = useParams<{ step?: string }>();
  const stepNr = step ? parseInt(step) : 1;
  const urlHandler = new UrlHandler();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const state = useSelector<AppState>((state) => state.leadRecording) as LeadRecordingState;
  return (
    <Paper
      sx={{
        height: '56px',
        flexShrink: 0,
      }}
      elevation={3}
    >
      <BottomNavigation
        sx={{
          backgroundColor: (theme) => theme.palette.navigation.main,
          justifyContent: 'space-evenly',
          height: '100%',
        }}
        showLabels
        value={null}
        onChange={async (_, newValue: number) => {
          if (newValue < 0) {
            try {
              const result = await confirm({
                title: 'Draft Lead',
                description: "We've saved a draft of this lead. Would you like to keep it?",
                confirmationText: 'Yes, keep it',
                showRejection: true,
                rejectionText: 'No, thanks',
                cancellationText: 'Cancel',
              });
              if (result === 'cancel') {
                return;
              }
              if (reset) {
                reset();
              }
              if (result === 'accept' && state.sessionId) {
                dispatch(storeAndReset(stepNr));
              } else {
                dispatch(leadRecordingSlice.actions.reset());
              }
              navigation(urlHandler.createLeadProcessUrl(newValue));
            } catch {
              return;
            }
            return;
          }

          if (newValue > stepNr && onNavigateNext) {
            onNavigateNext();
          }

          if (newValue < stepNr && onNavigatePrevious) {
            onNavigatePrevious();
          }

          if (newValue > stepNr && finalStepConfig) {
            setIsLoading(true);
            try {
              await finalStepConfig.action();
              if (state.sessionId) {
                try {
                  await db.leads.delete(state.sessionId);
                } catch (e) {
                  console.log('error occurred during deletion');
                }
              }
              setIsLoading(false);
              if (reset) {
                reset();
              }
              dispatch(leadRecordingSlice.actions.reset());
              navigation(urlHandler.createLeadProcessUrl(-1));
            } catch (e) {
              console.log(e);
              setIsLoading(false);
            }
            return;
          } else {
            navigation(urlHandler.createLeadProcessUrl(newValue));
          }
        }}
      >
        <XimBottomNavigationItem
          className={navigatePreviousDisabled ? 'disabled' : ''}
          disabled={navigatePreviousDisabled}
          label="Back"
          value={stepNr - 1 < 0 ? 0 : stepNr - 1}
          icon={<ArrowBackIosNewIcon />}
        />
        <XimBottomNavigationItem label="Home" value={-1} icon={<HomeIcon />} />
        <XimBottomNavigationItem
          className={navigateNextDisabled ? 'disabled' : ''}
          label={finalStepConfig?.label || 'Next'}
          disabled={navigateNextDisabled}
          value={stepNr + 1}
          icon={finalStepConfig ? <DoneIcon /> : <ArrowForwardIosIcon />}
        />
      </BottomNavigation>
      <Backdrop
        sx={{
          flexDirection: 'column',
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
        open={isLoading}
      >
        <CircularProgress color="primary" />
        <Typography sx={{ marginTop: (theme) => theme.spacing(1) }}>Finishing up. Please wait...</Typography>
      </Backdrop>
    </Paper>
  );
};
