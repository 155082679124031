import { Box } from '@mui/material';
import { db } from 'modules/common/core';
import { useLiveQuery } from 'dexie-react-hooks';
import { MainControls } from 'modules/common/components/navigation/MainControls/MainControls';
import { DraftLeadsAppBar } from '../AppBar/DraftLeadsAppBar';
import { DraftLeadsEmpty } from '../DraftLeadsList/DraftLeadsEmpty';
import { DraftLeadsList } from '../DraftLeadsList/DraftLeadsList';
import { useServices } from 'modules/recordLead/hooks';

export const DraftLeadsPage = (): JSX.Element => {
  const draftLeads = useLiveQuery(() => db.leads.toArray());
  const services = useServices();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <DraftLeadsAppBar draftLeads={draftLeads} />
      {draftLeads?.length ? <DraftLeadsList leads={draftLeads} /> : <DraftLeadsEmpty />}
      <MainControls availableOrganizations={services.metadataService?.getOrganizations() ?? []}></MainControls>
    </Box>
  );
};
