import { IconButton } from '@mui/material';
import { db } from 'modules/common/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback } from 'react';
import { useConfirm } from 'modules/common';

export const DeleteLead = ({ leadId }: { leadId: string }): JSX.Element => {
  const confirm = useConfirm();
  const onClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      const result = await confirm({
        title: 'Are you sure you want to delete this lead?',
      });
      if (result != 'accept') {
        return;
      }
      await db.leads.delete(leadId);
    },
    [leadId]
  );
  return (
    <IconButton edge="end" aria-label="delete" onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  );
};
