/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * XIMEA Leads API
 * API supporting the XIMEA Leads app  Important! When generating the file in Swagger, you have to make some manual changes as those cannot be represented correctly and have to be changed manually.  It is recommended, that you compare the new and old file in your IDE (f.e. VSC) and leave the changes that you did not do.  Those include    - changing of the BASE_PATH    - interface/type (Simple)CheckOptions    - InlineResponse (otherwise the compiler shows errors)    - some code changes in addPhoto function   - an exclamation in the configuration   - .json() in addPhoto which causes an exception
 *
 * OpenAPI spec version: 2.0.0
 * Contact: endi.zhupani@ximea.com
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from 'url';
import * as isomorphicFetch from 'isomorphic-fetch';
import { Configuration } from './configuration';

const BASE_PATH =
  process.env.NODE_ENV !== 'production'
    ? (process.env.REACT_APP_API_DEVELOPMENT as string).replace(/\/+$/, '')
    : (process.env.REACT_APP_API_PRODUCTION as string).replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration!: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = isomorphicFetch
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface CheckboxOptions
 */
export type CheckboxOptions = { [id: string]: string | undefined };
/**
 *
 * @export
 * @interface ConferenceBadgeData
 */
export interface ConferenceBadgeData {
  /**
   *
   * @type {string}
   * @memberof ConferenceBadgeData
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceBadgeData
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceBadgeData
   */
  company: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceBadgeData
   */
  id: string;
}
/**
 *
 * @export
 * @interface ConferenceParticipantData
 */
export interface ConferenceParticipantData {
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  jobTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  addressText?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  stateProvince?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  zipcode?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  jobDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  productApplication?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  usedProducts?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof ConferenceParticipantData
   */
  website?: string;
}
/**
 *
 * @export
 * @interface ContactcardIdBody
 */
export interface ContactcardIdBody {
  /**
   *
   * @type {Blob}
   * @memberof ContactcardIdBody
   */
  file?: Blob;
}
/**
 * A customer for which the lead is being created.
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  expoCustomerId?: string;
  /**
   * Indicates whether the API should try to refetch the data from any backend services
   * @type {boolean}
   * @memberof Customer
   */
  dataComplete: boolean;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  salutation: Customer.SalutationEnum;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  language: Customer.LanguageEnum;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  position?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  zip?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  state?: string;
  /**
   * ISO 3166-1 Alpha-2 country code
   * @type {string}
   * @memberof Customer
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  countryName?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  phone?: string;
  /**
   * If this is an already existing contact in the CRM, this will contain the CRM contact id
   * @type {string}
   * @memberof Customer
   */
  crmContactId?: string;
  /**
   * If the company is an already existing company in the CRM, this will contain the CRM Account ID
   * @type {string}
   * @memberof Customer
   */
  crmAccountNumber?: string;
  /**
   * The URL used to fetch the contact card photo from blob storage
   * @type {string}
   * @memberof Customer
   */
  contactCardLink?: string;
}

/**
 * @export
 * @namespace Customer
 */
export namespace Customer {
  /**
   * @export
   * @enum {string}
   */
  export enum SalutationEnum {
    Ms = 'ms',
    Mr = 'mr',
    Dr = 'dr',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LanguageEnum {
    De = 'de',
    En = 'en',
  }
}

/**
 * Priority of the case or lead
 * @export
 * @enum {string}
 */
export enum FollowUpPriority {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}
/**
 *
 * @export
 * @interface FollowUpProcessData
 */
export interface FollowUpProcessData {
  /**
   *
   * @type {boolean}
   * @memberof FollowUpProcessData
   */
  createCase?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FollowUpProcessData
   */
  sendInformationEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FollowUpProcessData
   */
  customizeInformationEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FollowUpProcessData
   */
  waitForCustomer?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  status: number;
  text(): string | PromiseLike<string>;
  /**
   * URL of the uploaded image.
   * @type {string}
   * @memberof InlineResponse200
   */
  imageUrl?: string;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   * The access token.
   * @type {string}
   * @memberof InlineResponse2001
   */
  token?: string;
}
/**
 *
 * @export
 * @interface InlineResponse401
 */
export interface InlineResponse401 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse401
   */
  token?: string;
}
/**
 *
 * @export
 * @interface LeadContent
 */
export interface LeadContent {
  /**
   * an identifier assigned to this lead
   * @type {string}
   * @memberof LeadContent
   */
  identifier: string;
  /**
   * Expo or road show name
   * @type {string}
   * @memberof LeadContent
   */
  leadSource: string;
  /**
   *
   * @type {string}
   * @memberof LeadContent
   */
  leadSourceLongName: string;
  /**
   * The user that must follow up on this lead
   * @type {User}
   * @memberof LeadContent
   */
  followUpUser: User;
  /**
   * The user that communicated with the lead prospect
   * @type {User}
   * @memberof LeadContent
   */
  ximeaContact: User;
  /**
   *
   * @type {FollowUpPriority}
   * @memberof LeadContent
   */
  followUpPriority: FollowUpPriority;
  /**
   *
   * @type {FollowUpProcessData}
   * @memberof LeadContent
   */
  followUpProcess: FollowUpProcessData;
  /**
   *
   * @type {Customer}
   * @memberof LeadContent
   */
  leadCustomer: Customer;
  /**
   * A list of brochures that should be sent to the customer
   * @type {Array<string>}
   * @memberof LeadContent
   */
  brochures?: Array<string>;
  /**
   * A list of manuals that should be sent to the customer
   * @type {Array<string>}
   * @memberof LeadContent
   */
  manuals?: Array<string>;
  /**
   * A list of guides that should be sent to the customer
   * @type {Array<string>}
   * @memberof LeadContent
   */
  guides?: Array<string>;
  /**
   * A list of links that should be sent to the customer
   * @type {Array<string>}
   * @memberof LeadContent
   */
  links?: Array<string>;
  /**
   * The cameras for which links should be sent to the customer
   * @type {string}
   * @memberof LeadContent
   */
  cameraLink?: string;
  /**
   * A guide not in the list of standard guides that should be sent to the customer
   * @type {string}
   * @memberof LeadContent
   */
  customGuide?: string;
  /**
   * Tasks that need to be performed by the backoffice
   * @type {string}
   * @memberof LeadContent
   */
  backofficeTasks?: string;
  /**
   * List of customer requirements for ximea products
   * @type {Array<string>}
   * @memberof LeadContent
   */
  requirements?: Array<string>;
  /**
   * Custom requirement not found in the list
   * @type {string}
   * @memberof LeadContent
   */
  customRequirement?: string;
  /**
   * A list of industries that the customer is involved in
   * @type {Array<string>}
   * @memberof LeadContent
   */
  industries?: Array<string>;
  /**
   * A list of channels where the customer learned about XIMEA
   * @type {Array<string>}
   * @memberof LeadContent
   */
  marketingChannels?: Array<string>;
  /**
   * A list of XIMEA products that the customer is interested in
   * @type {Array<string>}
   * @memberof LeadContent
   */
  customerProductInterests?: Array<string>;
  /**
   * A custom channel not included in the default list
   * @type {string}
   * @memberof LeadContent
   */
  customMarketingChannel?: string;
  /**
   * A custom industry not included in the default list
   * @type {string}
   * @memberof LeadContent
   */
  customIndustry?: string;
  /**
   * A custom interest not included in the default list
   * @type {string}
   * @memberof LeadContent
   */
  customInterest?: string;
  /**
   * Information about the customer project and camera usage
   * @type {string}
   * @memberof LeadContent
   */
  salesInformation?: string;
  /**
   * Additional customer information not related to a specific project
   * @type {string}
   * @memberof LeadContent
   */
  additionalSalesNotes?: string;
  /**
   * Tasks to be performed by one of the sales people that will follow up
   * @type {string}
   * @memberof LeadContent
   */
  salesTasks?: string;
}

/**
 *
 * @export
 * @interface RecordLeadRequest
 */
export interface RecordLeadRequest {
  /**
   *
   * @type {string}
   * @memberof RecordLeadRequest
   */
  cameraLink?: string;
}

/**
 *
 * @export
 * @interface TokenBody
 */
export interface TokenBody {
  /**
   * Password to authenticate the request.
   * @type {string}
   * @memberof TokenBody
   */
  password: string;
}
/**
 * A XIMEA user
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  initials?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  allowedOrganizations: Array<string>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  defaultOrganization: string;
}
/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Adds a lead to the temporary leads database
     * @summary Adds a lead to the database
     * @param {string} id The id of the participant
     * @param {string} org The organization where the lead should be posted to
     * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
     * @param {LeadContent} [body] Inventory item to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLead(id: string, org: string, ACCESS_TOKEN: string, body?: LeadContent, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling addLead.');
      }
      // verify required parameter 'org' is not null or undefined
      if (org === null || org === undefined) {
        throw new RequiredError('org', 'Required parameter org was null or undefined when calling addLead.');
      }

      const localVarPath = `/leads/{org}/{id}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'org'}}`, encodeURIComponent(String(org)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // // verify required parameter 'ACCESS_TOKEN' is not null or undefined
      if (ACCESS_TOKEN === null || ACCESS_TOKEN === undefined) {
        throw new RequiredError(
          'ACCESS_TOKEN',
          'Required parameter ACCESS_TOKEN was null or undefined when calling addLead.'
        );
      } else {
        localVarHeaderParameter['ACCESS_TOKEN'] = String(ACCESS_TOKEN);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'LeadContent' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint uploads an image for a contact card, identified by a unique ID.
     * @summary Upload a contact card image
     * @param {Blob} file
     * @param {string} id Unique identifier for the contact card.
     * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPhoto(file: Blob, id: string, ACCESS_TOKEN: string, options: any = {}): FetchArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling addPhoto.');
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling addPhoto.');
      }
      // verify required parameter 'ACCESS_TOKEN' is not null or undefined

      const localVarPath = `/contact-card/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (ACCESS_TOKEN === null || ACCESS_TOKEN === undefined) {
        throw new RequiredError(
          'ACCESS_TOKEN',
          'Required parameter ACCESS_TOKEN was null or undefined when calling addPhoto.'
        );
      } else {
        localVarHeaderParameter['ACCESS_TOKEN'] = String(ACCESS_TOKEN);
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      // added manually, change in newly generated file also
      const formData = new FormData();
      const fileEnding = file.type === 'image/png' ? '.png' : '.jpg';
      formData.append('file', file, id + '-' + Date.now() + fileEnding);
      localVarRequestOptions.body = formData;

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets the contact information for an expo participant from the expo API
     * @param {string} id The id of the participant
     * @param {string} lastName The last name of the conference participant
     * @param {string} expo The code of the exhibition
     * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLeadExpoData(id: string, lastName: string, expo: string, ACCESS_TOKEN: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getLeadExpoData.');
      }
      // verify required parameter 'lastName' is not null or undefined
      if (lastName === null || lastName === undefined) {
        throw new RequiredError(
          'lastName',
          'Required parameter lastName was null or undefined when calling getLeadExpoData.'
        );
      }
      // verify required parameter 'expo' is not null or undefined
      if (expo === null || expo === undefined) {
        throw new RequiredError('expo', 'Required parameter expo was null or undefined when calling getLeadExpoData.');
      }

      const localVarPath = `/contact-information/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lastName !== undefined) {
        localVarQueryParameter['lastName'] = lastName;
      }

      if (expo !== undefined) {
        localVarQueryParameter['expo'] = expo;
      }

      if (ACCESS_TOKEN === null || ACCESS_TOKEN === undefined) {
        throw new RequiredError(
          'ACCESS_TOKEN',
          'Required parameter ACCESS_TOKEN was null or undefined when calling addPhoto.'
        );
      } else {
        localVarHeaderParameter['ACCESS_TOKEN'] = String(ACCESS_TOKEN);
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint returns an access token if the correct password is provided.
     * @summary Get an access token
     * @param {TokenBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken(body: TokenBody, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling getToken.');
      }
      const localVarPath = `/token`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      console.log('localVarUrlObj', localVarUrlObj);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'TokenBody' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Adds a lead to the temporary leads database
     * @summary Adds a lead to the database
     * @param {string} id The id of the participant
     * @param {string} org The organization where the lead should be posted to
     * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
     * @param {LeadContent} [body] Inventory item to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLead(
      id: string,
      org: string,
      ACCESS_TOKEN: string,
      body?: LeadContent,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).addLead(
        id,
        org,
        ACCESS_TOKEN,
        body,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * This endpoint uploads an image for a contact card, identified by a unique ID.
     * @summary Upload a contact card image
     * @param {Blob} file
     * @param {string} id Unique identifier for the contact card.
     * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPhoto(
      file: Blob,
      id: string,
      ACCESS_TOKEN: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).addPhoto(file, id, ACCESS_TOKEN, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Gets the contact information for an expo participant from the expo API
     * @param {string} id The id of the participant
     * @param {string} lastName The last name of the conference participant
     * @param {string} expo The code of the exhibition
     * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLeadExpoData(
      id: string,
      lastName: string,
      expo: string,
      ACCESS_TOKEN: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getLeadExpoData(
        id,
        lastName,
        expo,
        ACCESS_TOKEN,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * This endpoint returns an access token if the correct password is provided.
     * @summary Get an access token
     * @param {TokenBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken(body: TokenBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getToken(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     * Adds a lead to the temporary leads database
     * @summary Adds a lead to the database
     * @param {string} id The id of the participant
     * @param {string} org The organization where the lead should be posted to
     * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
     * @param {LeadContent} [body] Inventory item to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLead(id: string, org: string, ACCESS_TOKEN: string, body?: LeadContent, options?: any) {
      return DefaultApiFp(configuration).addLead(id, org, ACCESS_TOKEN, body, options)(fetch, basePath);
    },
    /**
     * This endpoint uploads an image for a contact card, identified by a unique ID.
     * @summary Upload a contact card image
     * @param {Blob} file
     * @param {string} id Unique identifier for the contact card.
     * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPhoto(file: Blob, id: string, ACCESS_TOKEN: string, options?: any) {
      return DefaultApiFp(configuration).addPhoto(file, id, ACCESS_TOKEN, options)(fetch, basePath);
    },
    /**
     *
     * @summary Gets the contact information for an expo participant from the expo API
     * @param {string} id The id of the participant
     * @param {string} lastName The last name of the conference participant
     * @param {string} expo The code of the exhibition
     * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLeadExpoData(id: string, lastName: string, expo: string, ACCESS_TOKEN: string, options?: any) {
      return DefaultApiFp(configuration).getLeadExpoData(id, lastName, expo, ACCESS_TOKEN, options)(fetch, basePath);
    },
    /**
     * This endpoint returns an access token if the correct password is provided.
     * @summary Get an access token
     * @param {TokenBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken(body: TokenBody, options?: any) {
      return DefaultApiFp(configuration).getToken(body, options)(fetch, basePath);
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * Adds a lead to the temporary leads database
   * @summary Adds a lead to the database
   * @param {string} id The id of the participant
   * @param {string} org The organization where the lead should be posted to
   * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
   * @param {LeadContent} [body] Inventory item to add
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public addLead(id: string, org: string, ACCESS_TOKEN: string, body?: LeadContent, options?: any) {
    return DefaultApiFp(this.configuration).addLead(id, org, ACCESS_TOKEN, body, options)(this.fetch, this.basePath);
  }

  /**
   * This endpoint uploads an image for a contact card, identified by a unique ID.
   * @summary Upload a contact card image
   * @param {Blob} file
   * @param {string} id Unique identifier for the contact card.
   * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public addPhoto(file: Blob, id: string, ACCESS_TOKEN: string, options?: any) {
    return DefaultApiFp(this.configuration).addPhoto(file, id, ACCESS_TOKEN, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Gets the contact information for an expo participant from the expo API
   * @param {string} id The id of the participant
   * @param {string} lastName The last name of the conference participant
   * @param {string} expo The code of the exhibition
   * @param {string} ACCESS_TOKEN Access token for authorization. The token should be set to the value obtained from &#x60;localStorage.getItem(&#x27;token&#x27;)&#x60; in the client application.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getLeadExpoData(id: string, lastName: string, expo: string, ACCESS_TOKEN: string, options?: any) {
    return DefaultApiFp(this.configuration).getLeadExpoData(
      id,
      lastName,
      expo,
      ACCESS_TOKEN,
      options
    )(this.fetch, this.basePath);
  }

  /**
   * This endpoint returns an access token if the correct password is provided.
   * @summary Get an access token
   * @param {TokenBody} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getToken(body: TokenBody, options?: any) {
    return DefaultApiFp(this.configuration).getToken(body, options)(this.fetch, this.basePath);
  }
}
