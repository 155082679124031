import Dexie, { Table } from 'dexie';

export interface Lead {
  id: string;
  leadContent: string;
  company: string;
  customer: string;
  lastModifiedOn: Date;
  step: number;
}

export class LeadsDexie extends Dexie {
  leads!: Table<Lead>;

  constructor() {
    super('leads');
    this.version(1).stores({
      leads: '++id, company, customer, lastModifiedOn, step, leadContent', // Primary key and indexed props
    });
  }
}

export const db = new LeadsDexie();
