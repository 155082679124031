import { Grid, Typography } from '@mui/material';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { AppState, useAppDispatch } from 'store/store';
import { leadRecordingSlice } from 'modules/recordLead/core';
import { contactHasData } from 'modules/recordLead/utils/DataHandler';
import { CustomerDataInputChoice } from '../CustomerDataInputChoice';
import { IndustryList } from '../IndustryList';
import { Feature } from 'flagged';
import { CenteredContent, ContactCardUploadButton } from 'modules/common/components';
import { ProcessStepPage } from '../../ProcessStepPage/ProcessStepPage';
import { CustomerContactForm } from 'modules/common/components';
import { LeadCustomerFormData } from 'modules/recordLead/core/sliceTypes';

export const CustomerContactManualEntry = (): JSX.Element => {
  const leadCustomer = useSelector((state: AppState) => state.leadRecording.leadCustomer);
  const contactCardImage = useSelector((state: AppState) => state.leadRecording.contactCardImageUrl);
  const conferenceParticipantId = useSelector<AppState>((state) => state.leadRecording.conferenceParticipantId) as
    | string
    | undefined;
  const dispatch = useAppDispatch();

  const formik = useFormik<LeadCustomerFormData>({
    initialValues: leadCustomer || {
      address: '',
      companyName: '',
      countryCode: '',
      email: '',
      fullName: '',
      phone: '00',
      position: '',
      salutation: LeadCustomerFormData.SalutationEnum.Empty,
      language: LeadCustomerFormData.LanguageEnum.En,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().min(3, 'Must be at least 3 characters').notRequired(),
      companyName: Yup.string().min(1, 'Must be at least 1 character').notRequired(),
      email: Yup.string().email('Invalid email address').notRequired(),
      countryCode: Yup.string().min(1, 'Must be at least 1 character').notRequired(),
    }),
    onSubmit: (values) => {
      dispatch(leadRecordingSlice.actions.setCustomerContact(values));
    },
  });

  return (
    <ProcessStepPage
      onNavigateNext={() => {
        dispatch(leadRecordingSlice.actions.setCustomerContact(formik.values));
      }}
      navigateNextDisabled={
        (contactHasData(formik.values) && !formik.isValid) ||
        (!contactHasData(formik.values) && !contactCardImage && !conferenceParticipantId)
      }
      onNavigatePrevious={() => {
        dispatch(leadRecordingSlice.actions.setCustomerContact(formik.values));
      }}
      reset={() => {
        dispatch(leadRecordingSlice.actions.setCustomerContact(formik.values));
      }}
    >
      <CenteredContent>
        <Typography variant="h6">Enter the customer data</Typography>
      </CenteredContent>
      <Grid container spacing={2} sx={{ height: 'calc(100% - 16px)' }}>
        <Grid item xs={12} md={3} xl={3}>
          <ContactCardUploadButton></ContactCardUploadButton>
          <Feature name="expoBadgeScanningAvailable">
            <CustomerDataInputChoice></CustomerDataInputChoice>
          </Feature>
        </Grid>
        <Grid item xs={12} md={6} xl={7}>
          <CustomerContactForm key="customerForm" formik={formik}></CustomerContactForm>
        </Grid>
        <Grid sx={{ height: '100%' }} item xs={12} md={3} xl={2}>
          <IndustryList />
        </Grid>
      </Grid>
    </ProcessStepPage>
  );
};
