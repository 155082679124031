import { FollowUpPriority, FollowUpProcessData, User } from 'api-client/api';
import { ErrorMessage, SimpleCheckboxOptions } from 'modules/common/core/types';

/**
 *
 * @export
 * @interface AddressComponents
 */
export interface AddressComponents {
  /**
   *
   * @type {string}
   * @memberof AddressComponents
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof AddressComponents
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof AddressComponents
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof AddressComponents
   */
  zip?: string;
  /**
   *
   * @type {string}
   * @memberof AddressComponents
   */
  countryName?: string;
}

/**
 *
 * @export
 * @interface NameComponents
 */
export interface NameComponents {
  /**
   *
   * @type {string}
   * @memberof NameComponents
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof NameComponents
   */
  lastName?: string;
}
/**
 *
 * @export
 * @interface LeadCustomerFormData
 */
export interface LeadCustomerFormData {
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  fullName?: string;
  /**
   *
   * @type {NameComponents}
   * @memberof LeadCustomerFormData
   */
  nameComponents?: NameComponents;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  salutation: LeadCustomerFormData.SalutationEnum;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  language: LeadCustomerFormData.LanguageEnum;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  position?: string;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  address?: string;
  /**
   *
   * @type {AddressComponents}
   * @memberof LeadCustomerFormData
   */
  addressComponents?: AddressComponents;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerFormData
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof LeadCustomerFormData
   */
  dataComplete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LeadCustomerFormData
   */
  loading?: boolean;
}

/**
 * @export
 * @namespace LeadCustomerFormData
 */
export namespace LeadCustomerFormData {
  /**
   * @export
   * @enum {string}
   */
  export enum SalutationEnum {
    Empty = '',
    Mr = 'mr',
    Ms = 'ms',
    Dr = 'dr',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LanguageEnum {
    De = 'de',
    En = 'en',
  }
}

/**
 *
 * @export
 * @interface LeadCustomerCrmData
 */
export interface LeadCustomerCrmData {
  /**
   *
   * @type {string}
   * @memberof LeadCustomerCrmData
   */
  crmContactId?: string;
  /**
   *
   * @type {string}
   * @memberof LeadCustomerCrmData
   */
  crmAccountNumber?: string;
}

/**
 *
 * @export
 * @interface SalesNotes
 */
export interface SalesNotes {
  /**
   *
   * @type {string}
   * @memberof SalesNotes
   */
  projectInformation?: string;
  /**
   *
   * @type {string}
   * @memberof SalesNotes
   */
  otherNotes?: string;
}

export interface LeadRecordingState {
  recordingStarted: boolean;
  /**
   *
   * @type {ErrorMessage}
   * @memberof LeadRecordingState
   */
  error?: ErrorMessage;
  /**
   *
   * @type {User}
   * @memberof LeadRecordingState
   */
  ximeaContact?: User;
  /**
   *
   * @type {User}
   * @memberof LeadRecordingState
   */
  followUpUser?: User;
  /**
   *
   * @type {FollowUpPriority}
   * @memberof LeadRecordingState
   */
  followUpPriority?: FollowUpPriority;
  /**
   *
   * @type {FollowUpProcessData}
   * @memberof LeadRecordingState
   */
  followUpProcess: FollowUpProcessData;
  /**
   *
   * @type {LeadCustomerFormData}
   * @memberof LeadRecordingState
   */
  leadCustomer?: LeadCustomerFormData;
  /**
   *
   * @type {LeadCustomerCrmData}
   * @memberof LeadRecordingState
   */
  leadCustomerCrmData?: LeadCustomerCrmData;
  /**
   *
   * @type {string}
   * @memberof LeadRecordingState
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof LeadRecordingState
   */
  contactCardImageUrl?: string;
  /**
   *
   * @type {SalesNotes}
   * @memberof LeadRecordingState
   */
  salesInformation?: SalesNotes;
  /**
   *
   * @type {string}
   * @memberof LeadRecordingState
   */
  backofficeTasks?: string;
  /**
   *
   * @type {SimpleCheckboxOptions}
   * @memberof LeadRecordingState
   */
  brochures: SimpleCheckboxOptions;
  /**
   *
   * @type {SimpleCheckboxOptions}
   * @memberof LeadRecordingState
   */
  links: SimpleCheckboxOptions;
  /**
   *
   * @type {SimpleCheckboxOptions}
   * @memberof LeadRecordingState
   */
  manuals: SimpleCheckboxOptions;
  /**
   *
   * @type {SimpleCheckboxOptions}
   * @memberof LeadRecordingState
   */
  customerRequirements: SimpleCheckboxOptions;
  /**
   *
   * @type {string}
   * @memberof LeadRecordingState
   */
  customRequirement?: string;
  /**
   *
   * @type {SimpleCheckboxOptions}
   * @memberof LeadRecordingState
   */
  customerProductInterests: SimpleCheckboxOptions;
  /**
   *
   * @type {string}
   * @memberof LeadRecordingState
   */
  customProductInterest?: string;
  /**
   *
   * @type {SimpleCheckboxOptions}
   * @memberof LeadRecordingState
   */
  marketingChannels: SimpleCheckboxOptions;
  /**
   *
   * @type {string}
   * @memberof LeadRecordingState
   */
  customMarketingChannel?: string;
  /**
   *
   * @type {SimpleCheckboxOptions}
   * @memberof LeadRecordingState
   */
  customerIndustries: SimpleCheckboxOptions;
  /**
   *
   * @type {string}
   * @memberof LeadRecordingState
   */
  customIndustry?: string;
  /**
   *
   * @type {string}
   * @memberof LeadRecordingState
   */
  salesTasks?: string;
  /**
   *
   * @type {string}
   * @memberof LeadRecordingState
   */
  conferenceParticipantId?: string;
  /**
   *
   * @type {boolean}
   * @memberof LeadRecordingState
   */
  customerDataLoading: boolean;
  /**
   *
   * @type {SimpleCheckboxOptions}
   * @memberof LeadRecordingState
   */
  organizations: SimpleCheckboxOptions;

  targetedOrganization?: string;
}
