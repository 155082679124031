import { Grid, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import { MultiTitleLabel } from '../MultiTitleLabel/MultiTitleLabel';
import { SalesNotes } from 'modules/recordLead/core/sliceTypes';

export const SalesInformationForm = (props: { formikProps: FormikProps<SalesNotes> }): JSX.Element => {
  return (
    <form
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.formikProps.handleSubmit(e);
      }}
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={12}
            autoComplete="off"
            id="projectInformation"
            name="projectInformation"
            onChange={props.formikProps.handleChange}
            onBlur={props.formikProps.handleBlur}
            value={props.formikProps.values.projectInformation}
            label={
              <MultiTitleLabel
                title="Project information"
                subTitle="camera model, application, feature request, project, timeline, budget, probability"
              />
            }
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: 'white',
                },
              },
            }}
            variant="filled"
            error={props.formikProps.touched.projectInformation && !!props.formikProps.errors.projectInformation}
            helperText={props.formikProps.touched.projectInformation && props.formikProps.errors.projectInformation}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoComplete="off"
            multiline
            rows={11}
            id="otherNotes"
            name="otherNotes"
            onChange={props.formikProps.handleChange}
            onBlur={props.formikProps.handleBlur}
            value={props.formikProps.values.otherNotes}
            label={<MultiTitleLabel title="Other notes" subTitle="additional sales information" />}
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: 'white',
                },
              },
            }}
            variant="filled"
            error={props.formikProps.touched.otherNotes && !!props.formikProps.errors.otherNotes}
            helperText={props.formikProps.touched.otherNotes && props.formikProps.errors.otherNotes}
          />
        </Grid>
      </Grid>
    </form>
  );
};
