import prodAppConfig from 'appconfig.json';
import devAppConfig from 'appconfig.test.json';
import { IMetadataService } from '../core/interfaces';
import { MetadataService } from '../core/services';

export function createMetadataService(targetedOrganization: string | undefined): IMetadataService {
  const location = window.location.href;
  const metadataService: IMetadataService =
    process.env.NODE_ENV === 'production' && location.indexOf('staging') < 0
      ? new MetadataService(prodAppConfig, targetedOrganization)
      : new MetadataService(devAppConfig, targetedOrganization);
  return metadataService;
}
