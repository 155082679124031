import { Backdrop, Box, Chip, Fab, ListItemIcon, Menu, MenuItem, MenuItemProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useAppDispatch } from 'store/store';
import { leadRecordingSlice } from 'modules/recordLead/core';
import { styled } from '@mui/material/styles';

interface LeadRecordingMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
  availableOrganizations: string[];
  startLeadRecording: (org?: string) => void;
  showUndecidedOption?: boolean;
}

const OrganizationMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,

  '&:first-of-type': {
    borderTop: 'none',
  },
  '&.organization': {
    padding: theme.spacing(2, 2),
  },
}));

const OrganizationSelectionMenu: React.FC<LeadRecordingMenuProps> = ({
  anchorEl,
  open,
  handleClose,
  availableOrganizations,
  startLeadRecording,
  showUndecidedOption,
}) => {
  return (
    <>
      <Menu
        id="lead-recording-menu"
        aria-labelledby="lead-recording-button"
        anchorEl={anchorEl}
        open={open}
        sx={{
          paddingBottom: 0,
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        elevation={2}
      >
        <OrganizationMenuItem disabled={true}>Choose the organization:</OrganizationMenuItem>

        {availableOrganizations.map((org) => (
          <OrganizationMenuItem className="organization" key={org} onClick={() => startLeadRecording(org)}>
            <ListItemIcon>
              <Chip label={org.substring(0, 1).toUpperCase()} size="small" />
            </ListItemIcon>
            {org}
          </OrganizationMenuItem>
        ))}
        {showUndecidedOption && (
          <OrganizationMenuItem onClick={() => startLeadRecording()}>
            <ListItemIcon>
              <WatchLaterIcon />
            </ListItemIcon>
            Decide later
          </OrganizationMenuItem>
        )}
      </Menu>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose} />
    </>
  );
};

export const MainControls = ({ availableOrganizations }: { availableOrganizations: string[] }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (availableOrganizations.length > 1) {
      setAnchorEl(event.currentTarget);
    } else {
      startLeadRecording(availableOrganizations[0]);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const startLeadRecording = React.useCallback((organization?: string) => {
    console.log('Start recording lead for organization:', organization);
    dispatch(leadRecordingSlice.actions.startLeadRecording(organization));
    navigate('/lead-recording/1');
  }, []);
  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(1.5, 2),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Fab
        size="large"
        color="primary"
        id="lead-recording-button"
        aria-controls={open ? 'lead-recording-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          visibility: open ? 'hidden' : 'visible',
        }}
      >
        <AddIcon />
      </Fab>
      {availableOrganizations.length > 1 && (
        <OrganizationSelectionMenu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          availableOrganizations={availableOrganizations}
          startLeadRecording={startLeadRecording}
          showUndecidedOption={true}
        ></OrganizationSelectionMenu>
      )}
    </Box>
  );
};
