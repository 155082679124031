import { Grid } from '@mui/material';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { leadRecordingSlice } from 'modules/recordLead/core';
import { AppState, useAppDispatch } from 'store/store';
import { CheckableList, ResponsiveGridItem } from 'modules/common/components';
import { ProcessStepPage } from '../ProcessStepPage/ProcessStepPage';

const RequirementsList = (): JSX.Element => {
  const requirements = useSelector((state: AppState) => state.leadRecording.customerRequirements);
  const customRequirement = useSelector((state: AppState) => state.leadRecording.customRequirement);
  const dispatch = useAppDispatch();

  const updateRequirements = useCallback(
    (key: string, value: boolean) => {
      dispatch(leadRecordingSlice.actions.setRequirements({ ...requirements, [key]: value }));
    },
    [requirements, dispatch]
  );

  return (
    <CheckableList
      options={requirements}
      title="Customer requirements"
      onChange={updateRequirements}
      searchable={true}
      extensionConfig={{
        onExtensionChange: (value) => dispatch(leadRecordingSlice.actions.setCustomRequirement(value)),
        currentExtensionValue: customRequirement,
      }}
    ></CheckableList>
  );
};

const MarketingChannelsList = () => {
  const marketingChannels = useSelector((state: AppState) => state.leadRecording.marketingChannels);
  const customMarketingChannel = useSelector((state: AppState) => state.leadRecording.customMarketingChannel);
  const dispatch = useAppDispatch();
  return (
    <CheckableList
      options={marketingChannels}
      title="Marketing channels"
      onChange={(key, value) =>
        dispatch(leadRecordingSlice.actions.setMarketingChannels({ ...marketingChannels, [key]: value }))
      }
      searchable={true}
      extensionConfig={{
        currentExtensionValue: customMarketingChannel,
        onExtensionChange: (value) => dispatch(leadRecordingSlice.actions.setCustomMarketingChannel(value)),
      }}
    ></CheckableList>
  );
};

const ProductInterestList = () => {
  const productInterests = useSelector((state: AppState) => state.leadRecording.customerProductInterests);
  const customProductInterest = useSelector((state: AppState) => state.leadRecording.customProductInterest);
  const dispatch = useAppDispatch();
  return (
    <CheckableList
      options={productInterests}
      title="Customer product interests"
      onChange={(key, value) =>
        dispatch(leadRecordingSlice.actions.setCustomerProductInterests({ ...productInterests, [key]: value }))
      }
      searchable={true}
      extensionConfig={{
        currentExtensionValue: customProductInterest,
        onExtensionChange: (value) => dispatch(leadRecordingSlice.actions.setCustomProductInterest(value)),
      }}
    ></CheckableList>
  );
};

export const MarketingProfile = (): JSX.Element => {
  return (
    <ProcessStepPage>
      <Grid
        container
        spacing={2}
        sx={{
          height: '100%',
        }}
      >
        <ResponsiveGridItem
          sx={{
            height: '100%',
          }}
          item
          xs={12}
          sm={4}
        >
          <ProductInterestList />
        </ResponsiveGridItem>
        <ResponsiveGridItem
          item
          xs={12}
          sm={4}
          sx={{
            height: '100%',
          }}
        >
          <RequirementsList />
        </ResponsiveGridItem>
        <ResponsiveGridItem
          item
          xs={12}
          sm={4}
          sx={{
            height: '100%',
          }}
        >
          <MarketingChannelsList />
        </ResponsiveGridItem>
      </Grid>
    </ProcessStepPage>
  );
};
