import { Grid, TextField } from '@mui/material';

import { AppState, useAppDispatch } from 'store/store';
import { useSelector } from 'react-redux';
import { leadRecordingSlice } from 'modules/recordLead/core/leadRecordingSlice';
import { useState } from 'react';
import { CheckableList, MultiTitleLabel, ResponsiveGridItem } from 'modules/common/components';
import { ProcessStepPage } from '../ProcessStepPage/ProcessStepPage';

const BrochuresList = () => {
  const brochures = useSelector((state: AppState) => state.leadRecording.brochures);
  const dispatch = useAppDispatch();
  return (
    <CheckableList
      options={brochures}
      title="Send camera info"
      onChange={(key, value) =>
        dispatch(
          leadRecordingSlice.actions.setBrochures({
            ...brochures,
            [key]: value,
          })
        )
      }
      searchable={true}
    ></CheckableList>
  );
};

const ManualsList = () => {
  const manuals = useSelector((state: AppState) => state.leadRecording.manuals);

  const dispatch = useAppDispatch();
  return (
    <CheckableList
      options={manuals}
      title="Send manuals"
      onChange={(key, value) => dispatch(leadRecordingSlice.actions.setManuals({ ...manuals, [key]: value }))}
      searchable={true}
    ></CheckableList>
  );
};

const LinksList = () => {
  const links = useSelector((state: AppState) => state.leadRecording.links);
  const dispatch = useAppDispatch();
  return (
    <CheckableList
      options={links}
      title="Send links"
      onChange={(key, value) => dispatch(leadRecordingSlice.actions.setLinks({ ...links, [key]: value }))}
      searchable={true}
    ></CheckableList>
  );
};

export const BackofficeTasks = (): JSX.Element => {
  const brochures = useSelector((state: AppState) => state.leadRecording.brochures);
  const manuals = useSelector((state: AppState) => state.leadRecording.manuals);
  const links = useSelector((state: AppState) => state.leadRecording.links);

  const listsToDisplay = [];
  if (Object.keys(brochures).length > 0) {
    listsToDisplay.push(<BrochuresList />);
  }
  if (Object.keys(manuals).length > 0) {
    listsToDisplay.push(<ManualsList />);
  }
  if (Object.keys(links).length > 0) {
    listsToDisplay.push(<LinksList />);
  }
  return (
    <ProcessStepPage>
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        {listsToDisplay.length !== 0 && (
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            {listsToDisplay.map((list, index) => (
              <ResponsiveGridItem item xs={12} sm={Math.ceil(12 / listsToDisplay.length)} key={index}>
                {list}
              </ResponsiveGridItem>
            ))}
            {/* <ResponsiveGridItem item xs={12} sm={4}>
            <BrochuresList />
          </ResponsiveGridItem>
          <ResponsiveGridItem item xs={12} sm={4}>
            <ManualsList />
          </ResponsiveGridItem>
          <ResponsiveGridItem item xs={12} sm={4}>
            <LinksList />
          </ResponsiveGridItem> */}
          </Grid>
        )}
        <BackofficeTasksText></BackofficeTasksText>
      </div>
    </ProcessStepPage>
  );
};

const BackofficeTasksText = () => {
  const boTasks = useSelector((state: AppState) => state.leadRecording.backofficeTasks) as string;
  const [backofficeTasks, setBackofficeTasks] = useState<string>(boTasks || '');
  const dispatch = useAppDispatch();
  return (
    <div>
      <TextField
        fullWidth
        multiline
        rows={8}
        autoComplete="off"
        id="backofficeTasks"
        name="backofficeTasks"
        onChange={(e) => {
          setBackofficeTasks(e.target.value);
        }}
        onBlur={() => {
          dispatch(leadRecordingSlice.actions.setBackofficeTasks(backofficeTasks));
        }}
        sx={{
          marginTop: (theme) => theme.spacing(3),
        }}
        value={backofficeTasks}
        label={<MultiTitleLabel title="Backoffice tasks" subTitle="case, opportunity, quote, order" />}
        InputLabelProps={{
          sx: {
            '&.Mui-focused': {
              color: 'white',
            },
          },
        }}
        variant="filled"
      />
    </div>
  );
};
