import { FormControlLabel, Switch } from '@mui/material';
import { useSelector } from 'react-redux';
import { appConfigSlice } from 'modules/common/core';
import { AppState, useAppDispatch } from 'store/store';

export const CustomerDataInputChoice = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const expoBadgeScanning = useSelector<AppState>(
    (state) => state.appConfig.features.expoBadgeScanningEnabled
  ) as boolean;
  return (
    <FormControlLabel
      sx={{
        display: 'block',
      }}
      control={
        <Switch
          checked={expoBadgeScanning}
          onChange={() => dispatch(appConfigSlice.actions.setCustomerDataEntry(!expoBadgeScanning))}
          name="expoBadgeScanning"
          color="primary"
        />
      }
      label="Use QR Scanner"
    />
  );
};
