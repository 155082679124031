import { LeadCustomerFormData, AddressComponents } from '../core/sliceTypes';

export function contactHasData(data?: LeadCustomerFormData): boolean {
  if (data) {
    return !!data.companyName && !!data.fullName && !!data.email && !!data.countryCode;
  }
  return false;
}

export function downloadObjectAsJson<T>(exportObj: T, exportName: string): void {
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.style.display = 'none';
  downloadAnchorNode.setAttribute('download', exportName);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function downloadFile(url: string, fileTypeExtension: string, exportName: string): void {
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', url);
  downloadAnchorNode.style.display = 'none';
  downloadAnchorNode.setAttribute('download', exportName + '.' + fileTypeExtension);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function mergeAddressComponents(addressComponents: AddressComponents): string {
  let address = '';
  if (addressComponents.street) {
    address += addressComponents.street;
  }
  if (addressComponents.city) {
    if (address) {
      address += ', ';
    }
    address += addressComponents.city;
  }
  if (addressComponents.zip) {
    if (address) {
      address += ', ';
    }
    address += addressComponents.zip;
  }

  if (addressComponents.state) {
    if (address) {
      address += ', ';
    }
    address += addressComponents.state;
  }

  if (addressComponents.countryName) {
    if (address) {
      address += ', ';
    }
    address += addressComponents.countryName;
  }
  return address;
}
