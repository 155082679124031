import React, { ChangeEvent } from 'react';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Paper,
  Button,
  styled,
  Skeleton,
  Theme,
  ImageList,
  ImageListItem,
  IconButton,
  ImageListItemBar,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState, useAppDispatch } from 'store/store';

import { leadRecordingSlice } from 'modules/recordLead/core/leadRecordingSlice';
import { NO_CONNECTIVITY_ERROR } from 'modules/common/core';
import { useServices } from 'modules/recordLead/hooks';
import { ErrorMessage } from 'modules/common/core/types';

const ContactCardImage = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
}));
const ContactCardContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: 0,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: 'transparent',
}));

export const ContactCardUploadButton = (): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const sessionId = useSelector<AppState>((state: AppState) => state.leadRecording.sessionId) as string | undefined;
  const contactCardImageUrl = useSelector<AppState>((state: AppState) => state.leadRecording.contactCardImageUrl) as
    | string
    | undefined;
  const { filesService } = useServices();
  const dispatch = useAppDispatch();

  const handleUploadClick = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      dispatch(leadRecordingSlice.actions.setContactCardImageUrl(''));
      return;
    }
    setLoading(true);
    try {
      const file = await filesService?.resize(event.target.files[0]);

      if (!file || !sessionId) {
        throw new Error('File is not valid or the files service could not be initialized');
      }
      const tempUrl = URL.createObjectURL(file);
      dispatch(leadRecordingSlice.actions.setContactCardImageUrl(tempUrl));
      const fileUrl = await filesService?.upload(file, sessionId);
      if (!fileUrl) {
        throw new Error('There was an issue uploading the image.');
      }
      const parts = contactCardImageUrl?.split(',') ?? ([] as string[]);
      parts?.push(fileUrl);
      dispatch(leadRecordingSlice.actions.setContactCardImageUrl(parts?.join(',')));
    } catch (e) {
      if (!window.navigator.onLine) {
        dispatch(leadRecordingSlice.actions.setError(NO_CONNECTIVITY_ERROR));
      } else {
        let message = 'An unknown error occurred';
        if (typeof e === typeof Error) {
          message = (e as Error).message;
        }
        dispatch(leadRecordingSlice.actions.setError({ message, type: ErrorMessage.TypeEnum.Error }));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveClick = (url: string) => {
    const parts = contactCardImageUrl?.split(',');
    if (!parts) {
      return;
    }

    const idx = parts.findIndex((p) => p === url);
    if (idx >= 0) {
      parts.splice(idx, 1);
    }
    dispatch(leadRecordingSlice.actions.setContactCardImageUrl(parts.join(',')));
  };

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }
  if (contactCardImageUrl) {
    return (
      <>
        <ImageList cols={1} sx={{ maxHeight: '400px' }}>
          {contactCardImageUrl.split(',').map((url) => (
            <ImageListItem sx={{ margin: '0' }} key={url}>
              <ContactCardImage src={url}></ContactCardImage>
              <ImageListItemBar
                actionIcon={
                  <IconButton
                    onClick={() => handleRemoveClick(url)}
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    color="primary"
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
        <ContactCardContainer variant="outlined">
          <input
            onChange={handleUploadClick}
            accept="image/*"
            capture="environment"
            style={{ display: 'none' }}
            id="contact-card-upload"
            type="file"
          />
          <label style={{ width: '100%', height: '100%' }} htmlFor="contact-card-upload">
            <Button
              sx={{
                padding: (theme: Theme) => theme.spacing(0.5),
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              component="span"
            >
              <span
                style={{
                  width: '35px',
                  height: '35px',
                  marginBottom: '0.1rem',
                }}
              >
                <AddAPhotoIcon sx={{ width: '100%', height: '100%' }} />
              </span>
              Add More
            </Button>
          </label>
        </ContactCardContainer>
      </>
    );
  }

  return (
    <ContactCardContainer variant="outlined">
      <input
        onChange={handleUploadClick}
        accept="image/*"
        capture="environment"
        style={{ display: 'none' }}
        id="contact-card-upload"
        type="file"
      />
      <label style={{ width: '100%', height: '100%' }} htmlFor="contact-card-upload">
        <Button
          sx={{
            padding: (theme: Theme) => theme.spacing(2),
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          component="span"
        >
          <div
            style={{
              width: '100px',
              height: '100px',
              marginBottom: '1rem',
            }}
          >
            <AddAPhotoIcon sx={{ width: '100%', height: '100%' }} />
          </div>
          Add photo(s)
        </Button>
      </label>
    </ContactCardContainer>
  );
};
