import { Box, Typography } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';

export const DraftLeadsEmpty = (): JSX.Element => {
  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(6, 2, 1),
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <InboxIcon
        sx={{
          fontSize: '5rem',
        }}
        color="disabled"
      ></InboxIcon>
      <Typography variant="h5">Great Job!</Typography>
      <Typography color="GrayText">You have processed all your leads.</Typography>
    </Box>
  );
};
