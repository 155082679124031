import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FormikProps } from 'formik';
import { LeadCustomerFormData } from 'modules/recordLead/core/sliceTypes';
import { useServices } from 'modules/recordLead/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

import { useDebouncedCallback } from 'use-debounce';

export const CustomerContactForm = ({ formik }: { formik: FormikProps<LeadCustomerFormData> }): JSX.Element => {
  const { metadataService } = useServices();
  const photoUrl = useSelector<AppState>((state) => state.leadRecording.contactCardImageUrl) as string | undefined;
  const conferenceParticipantId = useSelector<AppState>((state) => state.leadRecording.conferenceParticipantId) as
    | string
    | undefined;
  if (!metadataService) {
    throw new Error('Metadata service not available');
  }

  const countries = metadataService.getCountries();
  return (
    <form
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={4} md={2} xl={1}>
          <RadioGroup
            aria-label="language"
            value={formik.values.language}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="language"
            id="langauge"
          >
            <FormControlLabel value="en" control={<Radio size="small" />} label="EN" />
            <FormControlLabel value="de" control={<Radio size="small" />} label="DE" />
          </RadioGroup>
        </Grid>
        <Grid item xs={8} md={4} xl={3}>
          <FormControl fullWidth>
            <InputLabel id="salutation-label">Salutation</InputLabel>
            <Select
              native
              labelId="salutation-label"
              id="salutation"
              variant="standard"
              value={formik.values.salutation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value={undefined}></option>
              <option value="ms">Ms.</option>
              <option value="mr">Mr.</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} xl={8}>
          <TextFieldWrapper
            autoComplete="off"
            fullWidth
            id="fullName"
            name="fullName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fullName}
            label={photoUrl || conferenceParticipantId ? 'Full name' : 'Full name*'}
            variant="standard"
            error={formik.touched.fullName && !!formik.errors.fullName}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldWrapper
            autoComplete="off"
            fullWidth
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            label={photoUrl || conferenceParticipantId ? 'Email' : 'Email*'}
            variant="standard"
            type="email"
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldWrapper
            fullWidth
            autoComplete="off"
            id="companyName"
            name="companyName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyName}
            label={photoUrl || conferenceParticipantId ? 'Company' : 'Company*'}
            variant="standard"
            error={formik.touched.companyName && !!formik.errors.companyName}
            helperText={formik.touched.companyName && formik.errors.companyName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldWrapper
            fullWidth
            autoComplete="off"
            id="position"
            name="position"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.position}
            label="Position"
            variant="standard"
            error={formik.touched.position && !!formik.errors.position}
            helperText={formik.touched.position && formik.errors.position}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldWrapper
            autoComplete="off"
            fullWidth
            id="address"
            name="address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            label="Address"
            variant="standard"
            error={formik.touched.address && !!formik.errors.address}
            helperText={formik.touched.address && formik.errors.address}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="countryCode-label"></InputLabel>
            <Autocomplete
              //labelId="countryCode-label"
              id="countryCode"
              options={countries}
              getOptionLabel={(option) => option.name}
              value={countries.find((x) => x.iso2AlphaCode === formik.values.countryCode) || null}
              //onChange={formik.handleChange}
              //onBlur={formik.handleBlur}
              onChange={(event, newValue) => {
                formik.setFieldValue('countryCode', newValue ? newValue.iso2AlphaCode : '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={photoUrl || conferenceParticipantId ? 'Country' : 'Country*'}
                  variant="standard"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off', // disable autocomplete and autofill
                  }}
                />
              )}
            ></Autocomplete>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldWrapper
            autoComplete="off"
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '([0-9]|+)*' }}
            id="phone"
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            label="Phone"
            variant="standard"
            error={formik.touched.phone && !!formik.errors.phone}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const INPUT_DELAY = 500;

const TextFieldWrapper = (props: TextFieldProps) => {
  const [innerValue, setInnerValue] = useState('');

  useEffect(() => {
    if (props.value) {
      setInnerValue(props.value as string);
    } else {
      setInnerValue('');
    }
  }, [props.value]);

  const debouncedHandleOnChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event);
    }
  }, INPUT_DELAY);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    const newValue = event.currentTarget.value;
    setInnerValue(newValue);
    debouncedHandleOnChange(event);
  }, []);

  return <TextField {...props} value={innerValue} onChange={handleOnChange} />;
};
