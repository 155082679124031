import { createContext } from 'react';
import { useSelector } from 'react-redux';
import { FilesService, LeadsService, DefaultDataService } from 'modules/recordLead/core/services';
import { SpieDataParsingService } from 'modules/recordLead/core/services/spie/';
import {
  IConferenceDataService,
  IFilesService,
  ILeadsService,
  IDataParsingService,
} from 'modules/recordLead/core/interfaces';
import { ExpoData } from 'modules/common/core/types';
import { AppState, useAppSelector } from 'store/store';
import { ConferenceApiDataService } from 'modules/recordLead/core/services';
import { AuvsiDataParsingService } from 'modules/recordLead/core/services/auvsi';
import { EmptyDataParsingService } from 'modules/recordLead/core/services/EmptyDataParsingService';
import { targetedOrganizationSelector } from 'modules/recordLead/core/leadRecordingSlice';
import { serviceFactory } from 'modules/common/utils';
import { IMetadataService } from 'modules/common/core/interfaces';

export type ServicesState = {
  readonly metadataService?: IMetadataService;
  readonly leadsService?: ILeadsService;
  readonly filesService?: IFilesService;
  readonly conferenceDataService?: IConferenceDataService;
  readonly dataParsingService?: IDataParsingService;
};
const initialState: ServicesState = {
  metadataService: undefined,
  leadsService: undefined,
  filesService: undefined,
  conferenceDataService: undefined,
};

export const ServicesContext = createContext<ServicesState>(initialState);

export const ServicesProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const apiAvailable = (process.env.REACT_APP_EXPO_API_AVAILABLE as string) === 'true';

  const getExpoSpecificDataParsingService = (expo: ExpoData): IDataParsingService => {
    const expoId = expo.expoId.toLowerCase();
    if (expoId.startsWith('phow')) {
      return new SpieDataParsingService();
    }
    if (expoId.startsWith('auvsi')) {
      return new AuvsiDataParsingService();
    }
    if (apiAvailable) {
      throw new Error('Data cannot be extracted from badges for this expo.');
    }
    return new EmptyDataParsingService();
  };
  const targetedOrganization = useAppSelector(targetedOrganizationSelector);

  const metadataService: IMetadataService = serviceFactory.createMetadataService(targetedOrganization);
  let services: ServicesState = { metadataService: metadataService };
  const expoData = useSelector<AppState>((state) => state.appConfig.conferenceData) as ExpoData;
  if (expoData) {
    services = {
      ...services,
      leadsService: new LeadsService({
        expoId: expoData.expoId,
        expoName: expoData.expoName,
      }),
      filesService: new FilesService(),
      conferenceDataService: apiAvailable
        ? new ConferenceApiDataService(
            { metadataService: metadataService },
            getExpoSpecificDataParsingService(expoData)
          )
        : new DefaultDataService(metadataService),
      dataParsingService: apiAvailable ? getExpoSpecificDataParsingService(expoData) : undefined,
    };
  }

  return <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>;
};
