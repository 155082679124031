import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import ReactTimeAgo from 'react-time-ago';
import { Lead } from 'modules/common/core/db';
import { leadRecordingSlice } from 'modules/recordLead/core/leadRecordingSlice';
import { useAppDispatch } from 'store/store';

import { UrlHandler } from 'modules/common/utils';
import { DeleteLead } from '../DeleteLead/DeleteLead';
import { LeadRecordingState } from 'modules/recordLead/core/sliceTypes';

/**
 * Component that displays a lead in a list.
 * @param props A lead data object with the data that should be displayed in this list item
 * @returns
 */
export const LeadListItemContent = ({
  leadData,
}: {
  leadData: { contactName: string; companyName: string; lastModifiedOn: Date; initialOrganization?: string };
}): JSX.Element => {
  const { contactName, companyName, lastModifiedOn, initialOrganization } = leadData;
  return (
    <ListItemText
      primary={initialOrganization ? `${contactName} - ${initialOrganization}` : `${contactName}`}
      secondary={
        <>
          <Typography
            sx={{
              fontSize: '0.8rem',
            }}
            component="span"
          >
            {companyName}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: '0.65rem',
              display: 'block',
            }}
          >
            <ReactTimeAgo date={lastModifiedOn} locale="en-US" />
          </Typography>
        </>
      }
    />
  );
};

export const LeadListItem = ({ lead }: { lead: Lead }): JSX.Element => {
  const navigate = useNavigate();
  const urlHandler = new UrlHandler();
  const dispatch = useAppDispatch();
  const parsedLead = JSON.parse(lead.leadContent) as LeadRecordingState;
  const openLead = useCallback(() => {
    dispatch(leadRecordingSlice.actions.setLeadToEdit(parsedLead));
    navigate(urlHandler.createLeadProcessUrl(lead.step));
  }, [dispatch, navigate, urlHandler, lead]);
  return (
    <ListItem
      sx={{
        boxShadow: (theme) => theme.shadows[2],
        padding: 0,
        backgroundColor: (theme) => theme.palette.background.paper,
        margin: (theme) => theme.spacing(1, 0),
        borderRadius: (theme) => theme.shape.borderRadius / 2,
      }}
    >
      <ListItemButton onClick={openLead}>
        <LeadListItemContent
          leadData={{
            companyName: !lead.company ? 'No Company Name' : lead.company,
            contactName: !lead.customer ? 'No Contact Name' : lead.customer,
            lastModifiedOn: lead.lastModifiedOn,
            initialOrganization: parsedLead.targetedOrganization,
          }}
        />
        <ListItemIcon
          sx={{
            minWidth: 'auto',
          }}
        >
          <DeleteLead leadId={lead.id} />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
};
