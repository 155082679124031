import { IFilesService } from 'modules/recordLead/core/interfaces';
import imageCompression from 'browser-image-compression';
import { DefaultApi } from 'api-client/api';
import { downloadFile } from 'modules/recordLead/utils/DataHandler';
import { STORAGE_CONFIG } from 'modules/common/core/azure';

export class FilesService implements IFilesService {
  private blobRoot = STORAGE_CONFIG.blobRoot as string;

  private readonly api: DefaultApi;
  /**
   *
   */
  constructor() {
    this.api = new DefaultApi();
    if (this.blobRoot.endsWith('/')) {
      this.blobRoot = this.blobRoot.slice(0, -1);
    }
  }
  async resize(file: File): Promise<File> {
    const options = {
      maxSizeMB: 0.6,
      maxWidthOrHeight: 750,
    };
    return await imageCompression(file, options);
  }

  async upload(file: File, uniqueId: string): Promise<string> {
    const fileNameParts = file.name.split('.');
    try {
      const response = await this.api.addPhoto(file, uniqueId, localStorage.getItem('token') as string);
      if (response.status < 200 || response.status > 299) {
        throw new Error(`The file upload failed with a status of ${response.status}`);
      }

      const responseText = (await response.text()) as string;
      const responseObj: { imageUrl: string } = JSON.parse(responseText);
      return responseObj.imageUrl;
    } catch (e) {
      downloadFile(URL.createObjectURL(file).toString(), fileNameParts[fileNameParts.length - 1], uniqueId);
      throw e;
    }
  }
}
