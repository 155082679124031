const STORAGE_ACCOUNT_ROOT =
  process.env.NODE_ENV !== 'production'
    ? (process.env.REACT_APP_BLOB_ROOT_URL_DEV as string)
    : (process.env.REACT_APP_BLOB_ROOT_URL_PROD as string);

const CONTAINER_NAME =
  process.env.NODE_ENV !== 'production'
    ? (process.env.REACT_APP_CONTAINER_NAME_DEV as string)
    : (process.env.REACT_APP_CONTAINER_NAME_PROD as string);

const blobRoot = STORAGE_ACCOUNT_ROOT;
const containerName = CONTAINER_NAME;

export const STORAGE_CONFIG = {
  blobRoot: blobRoot,
  containerName: containerName,
};
