import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  followUpPrioritySelector,
  followUpProcessSelector,
  leadRecordingSlice,
  salesTasksSelector,
  setFollowUpPriority,
  setFollowUpProcess,
} from 'modules/recordLead/core/leadRecordingSlice';
import { AppState, useAppDispatch, useAppSelector } from 'store/store';
import { FollowUpPriority, User } from 'api-client/api';
import { NO_CONNECTIVITY_ERROR } from 'modules/common/core';
import { useServices } from 'modules/recordLead/hooks';
import { ProcessStepPage } from '../ProcessStepPage/ProcessStepPage';
import { CenteredContent, CheckableList, HorizontalUserList, MultiTitleLabel } from 'modules/common/components';
import { LeadRecordingState } from 'modules/recordLead/core/sliceTypes';

export const FollowUp = (): JSX.Element => {
  const services = useServices();

  const dispatch = useAppDispatch();
  const leadState = useSelector<AppState>((state: AppState) => state.leadRecording) as LeadRecordingState;

  const saveLead = async () => {
    if (!services.leadsService) {
      return;
    }

    try {
      await services.leadsService.saveLead(leadState);
    } catch (e) {
      if (window.navigator.onLine) {
        throw e;
      } else {
        dispatch(leadRecordingSlice.actions.setError(NO_CONNECTIVITY_ERROR));
      }
    }
  };
  const organizations = useSelector((state: AppState) => state.leadRecording.organizations);
  const isOrganizationSelected = Object.values(organizations).some((value) => value) || leadState.targetedOrganization;

  return (
    <ProcessStepPage
      navigateNextDisabled={!isOrganizationSelected}
      finalStepConfig={{
        label: 'Done',
        action: saveLead,
      }}
    >
      <FollowUpUser></FollowUpUser>
      <FollowUpPrio></FollowUpPrio>
      <FollowUpProcess></FollowUpProcess>

      {!leadState.targetedOrganization && <RelevantOrganization></RelevantOrganization>}
      <SalesNotes></SalesNotes>
    </ProcessStepPage>
  );
};

const FollowUpUser = () => {
  const services = useServices();
  const followUpUser = useSelector<AppState>((state: AppState) => state.leadRecording.followUpUser) as User | undefined;

  const dispatch = useAppDispatch();
  return (
    <>
      <CenteredContent>
        <Typography variant="h6">Follow-up</Typography>
      </CenteredContent>
      <HorizontalUserList
        users={services.metadataService?.getSalesPeople() ?? []}
        selected={followUpUser}
        onItemClick={(user: User) => dispatch(leadRecordingSlice.actions.setFollowUpUser(user))}
      ></HorizontalUserList>
    </>
  );
};

const SalesNotes = () => {
  const salesTasks = useAppSelector(salesTasksSelector);
  const [additionalNotes, setAdditionalNotes] = React.useState(salesTasks || '');

  const dispatch = useAppDispatch();
  return (
    <div>
      <TextField
        fullWidth
        multiline
        rows={8}
        autoComplete="off"
        id="salesTasks"
        name="salesTasks"
        onChange={(e) => {
          setAdditionalNotes(e.target.value);
        }}
        onBlur={() => {
          dispatch(leadRecordingSlice.actions.setAdditionalNotes(additionalNotes));
        }}
        sx={{
          marginTop: (theme) => theme.spacing(3),
        }}
        value={additionalNotes}
        label={<MultiTitleLabel title="Sales tasks" subTitle="tasks for the follow up person" />}
        InputLabelProps={{
          sx: {
            '&.Mui-focused': {
              color: 'white',
            },
          },
        }}
        variant="filled"
      />
    </div>
  );
};

/**
 *
 * @returns Component for selecting the follow up priority
 */
const FollowUpPrio = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const followUpPriority = useAppSelector(followUpPrioritySelector);
  const handleChange = (_: React.MouseEvent<HTMLElement>, newFollowUpPriority: FollowUpPriority) => {
    dispatch(setFollowUpPriority(newFollowUpPriority));
  };

  return (
    <>
      <CenteredContent sx={{ marginTop: (theme) => theme.spacing(3) }}>
        <Typography variant="h6">Follow-up priority</Typography>
      </CenteredContent>
      <CenteredContent sx={{ marginTop: (theme) => theme.spacing(1) }}>
        <ToggleButtonGroup
          fullWidth={isMobile}
          color="primary"
          value={followUpPriority}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="High">High</ToggleButton>
          <ToggleButton value="Medium">Medium</ToggleButton>
          <ToggleButton value="Low">Low</ToggleButton>
        </ToggleButtonGroup>
      </CenteredContent>
    </>
  );
};

const FollowUpProcess = () => {
  const dispatch = useAppDispatch();
  const followUpProcess = useAppSelector(followUpProcessSelector);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newState = {
        ...followUpProcess,
        [event.target.name]: event.target.checked,
      };

      dispatch(setFollowUpProcess(newState));
    },
    [dispatch, followUpProcess]
  );

  return (
    <>
      <CenteredContent sx={{ marginTop: (theme) => theme.spacing(3) }}>
        <Typography variant="h6">Follow-up process</Typography>
      </CenteredContent>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={followUpProcess.createCase} onChange={handleChange} name="createCase" />}
            label="Create Case"
          />
          <FormControlLabel
            control={
              <Switch
                checked={followUpProcess.sendInformationEmail}
                onChange={handleChange}
                name="sendInformationEmail"
              />
            }
            label="Send Information Email"
          />
          <FormControlLabel
            hidden={!followUpProcess.sendInformationEmail}
            disabled={!followUpProcess.sendInformationEmail}
            control={
              <Switch
                checked={followUpProcess.customizeInformationEmail}
                hidden={!followUpProcess.sendInformationEmail}
                onChange={handleChange}
                name="customizeInformationEmail"
              />
            }
            label="Customize Information Email"
          />
          <FormControlLabel
            disabled={!followUpProcess.createCase || !followUpProcess.sendInformationEmail}
            control={
              <Switch
                checked={followUpProcess.waitForCustomer}
                disabled={!followUpProcess.createCase || !followUpProcess.sendInformationEmail}
                onChange={handleChange}
                name="waitForCustomer"
              />
            }
            label="Wait for Customer"
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

const RelevantOrganization = () => {
  const organizations = useSelector((state: AppState) => state.leadRecording.organizations);
  const followUpUser = useSelector<AppState>((state: AppState) => state.leadRecording.followUpUser) as User | undefined;
  const dispatch = useAppDispatch();

  // React.useEffect(() => {
  //   if (followUpUser) {
  //     const setOrgs: { [key: string]: boolean } = {};
  //     APPLICATION_CONFIG.organizations.forEach((org: string) => {
  //       setOrgs[org] = org === followUpUser.defaultOrganization;
  //     });
  //     dispatch(leadRecordingSlice.actions.setOrganizations(setOrgs));
  //   }
  // }, [followUpUser, dispatch]);

  if (followUpUser && followUpUser.allowedOrganizations.length > 1) {
    return (
      <>
        <CenteredContent sx={{ marginTop: (theme) => theme.spacing(3) }}>
          <Typography variant="h6">Relevant Organization(s)</Typography>
        </CenteredContent>
        <CheckableList
          options={organizations}
          title=""
          onChange={(key, value) =>
            dispatch(
              leadRecordingSlice.actions.setOrganizations({
                ...organizations,
                [key]: value,
              })
            )
          }
          searchable={false}
        ></CheckableList>
        {Object.values(organizations).every((value) => !value) && (
          <Typography variant="body2" color="error">
            Please select at least one organization.
          </Typography>
        )}
      </>
    );
  } else return <></>;
};
