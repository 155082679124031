import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { leadRecordingSlice } from 'modules/recordLead/core';
import { AppState, useAppDispatch } from 'store/store';
import { ProcessStepPage } from '../ProcessStepPage/ProcessStepPage';
import { SalesInformationForm } from 'modules/common/components';
import { SalesNotes } from 'modules/recordLead/core/sliceTypes';

export const SalesInformation = (): JSX.Element => {
  const salesInformation = useSelector((state: AppState) => state.leadRecording.salesInformation);
  const dispatch = useAppDispatch();
  const formik = useFormik<SalesNotes>({
    initialValues: salesInformation ?? {},

    onSubmit: (values) => {
      dispatch(leadRecordingSlice.actions.setSalesInformation(values));
    },
  });
  return (
    <ProcessStepPage
      onNavigateNext={() => {
        dispatch(leadRecordingSlice.actions.setSalesInformation(formik.values));
      }}
      onNavigatePrevious={() => {
        dispatch(leadRecordingSlice.actions.setSalesInformation(formik.values));
      }}
      reset={() => {
        dispatch(leadRecordingSlice.actions.setSalesInformation(formik.values));
      }}
    >
      <Grid
        sx={{
          paddingTop: (theme) => theme.spacing(2),
        }}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <SalesInformationForm formikProps={formik}></SalesInformationForm>
        </Grid>
      </Grid>
    </ProcessStepPage>
  );
};
