import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { leadRecordingSlice } from 'modules/recordLead/core/leadRecordingSlice';
import { AppState, useAppDispatch } from 'store/store';
import { User } from 'api-client/api';
import { useServices } from 'modules/recordLead/hooks';
import { ProcessStepPage } from '../ProcessStepPage/ProcessStepPage';
import { CenteredContent, VerticalUserList } from 'modules/common/components';

export const XimeaContact = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const services = useServices();
  const ximeaContact = useSelector<AppState>((state: AppState) => state.leadRecording.ximeaContact) as User | undefined;
  return (
    <ProcessStepPage navigateNextDisabled={!ximeaContact}>
      <CenteredContent>
        <Typography variant="h6">Who did the customer talk to?</Typography>
        <VerticalUserList
          selected={ximeaContact}
          onItemClick={(item) => {
            dispatch(leadRecordingSlice.actions.setXimeaContact(item));
            navigate(`/lead-recording/2`);
          }}
          users={services.metadataService?.getExpoParticipants() ?? []}
        ></VerticalUserList>
      </CenteredContent>
    </ProcessStepPage>
  );
};
