import { Typography } from '@mui/material';

export const MultiTitleLabel = (props: { title: string; subTitle: string }): JSX.Element => {
  return (
    <>
      <Typography
        component="span"
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontSize: '18px',
        }}
      >
        {props.title}
      </Typography>
      <Typography
        sx={{
          marginLeft: (theme) => theme.spacing(1),
          fontSize: '14px',
        }}
        component="span"
      >
        {props.subTitle}
      </Typography>
    </>
  );
};
