import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConfig, Features } from './types';
import { serviceFactory } from '../utils';
const metadataService = serviceFactory.createMetadataService('');
const expoData = metadataService.getExpoData();
export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: {
    features: {
      expoBadgeScanningEnabled: false,
      expoBadgeScanningAvailable: false,
      expoBadgeScanningLegacyMode: false,
      expoApiAvailable: false,
    },
    conferenceData: {
      ...expoData,
    },
  } as AppConfig,
  reducers: {
    setCustomerDataEntry(state, action: PayloadAction<boolean>) {
      state.features.expoBadgeScanningEnabled = action.payload;
    },
    setScanningLegacyMode(state, action: PayloadAction<boolean>) {
      const features = {
        ...state.features,
        expoBadgeScanningLegacyMode: action.payload,
      };
      return { ...state, features };
    },
    resetBadgeScanning(state) {
      state.features.expoBadgeScanningEnabled = state.features.expoBadgeScanningAvailable;
    },
  },
});

export const featuresSelector = (state: { appConfig: AppConfig }): Features => state.appConfig.features;

export const { setCustomerDataEntry, setScanningLegacyMode, resetBadgeScanning } = appConfigSlice.actions;
