import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { MainControls } from '../MainControls/MainControls';
import { useServices } from 'modules/recordLead/hooks';
export const PageWithMainControls: FunctionComponent<
  React.PropsWithChildren<{ withAppBar?: boolean; appBarTitle?: string }>
> = (props) => {
  const services = useServices();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {props.withAppBar && (
        <AppBar position="static">
          <Toolbar>
            <IconButton
              component={Link}
              to="/"
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {props.appBarTitle ?? 'Lead Recording'}
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {props.children}
      <MainControls availableOrganizations={services.metadataService?.getOrganizations() ?? []}></MainControls>
    </Box>
  );
};
