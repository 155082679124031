import { User } from 'api-client/api';
import { IMetadataService } from '../interfaces';
// import * as appConfig from 'appconfig.json';
// const appConfig: AppConfig = require('appconfig.json');

export type AppConfig = {
  expoId: string;
  expoName: string;
  exhibitorId: string;
  manuals: { [organization: string]: string[] };
  customerProductInterests: { [organization: string]: string[] };
  marketingChannels: { [organization: string]: string[] };
  salesPeople: { [organization: string]: User[] };
  expoParticipants: User[];
  brochures: { [organization: string]: string[] };
  guides: { [organization: string]: string[] };
  links: { [organization: string]: string[] };
  customerRequirements: { [key: string]: string[] };
  customerIndustries: { [organization: string]: string[] };
  organizations: string[];
  countries: { iso2AlphaCode: string; name: string }[];
};

export class MetadataService implements IMetadataService {
  /**
   *
   */
  constructor(private appConfig: AppConfig, private targetedOrganization?: string) {}
  getExpoData(): { expoId: string; expoName: string; exhibitorId: string } {
    return {
      expoId: this.appConfig.expoId,
      expoName: this.appConfig.expoName,
      exhibitorId: this.appConfig.exhibitorId,
    };
  }
  getManuals(): string[] {
    const manuals: string[] = [];
    if (!this.targetedOrganization) {
      for (const organization of this.appConfig.organizations) {
        manuals.push(...this.appConfig.manuals[organization]);
      }
    } else {
      manuals.push(...this.appConfig.manuals[this.targetedOrganization]);
    }
    return Array.from(new Set(manuals)).sort();
  }
  getCustomerProductInterest(): string[] {
    const customerProductInterests: string[] = [];
    if (!this.targetedOrganization) {
      for (const organization of this.appConfig.organizations) {
        customerProductInterests.push(...this.appConfig.customerProductInterests[organization]);
      }
    } else {
      customerProductInterests.push(...this.appConfig.customerProductInterests[this.targetedOrganization]);
    }
    return Array.from(new Set(customerProductInterests)).sort();
  }
  getMarketingChannels(): string[] {
    const marketingChannels: string[] = [];
    if (!this.targetedOrganization) {
      for (const organization of this.appConfig.organizations) {
        marketingChannels.push(...this.appConfig.marketingChannels[organization]);
      }
    } else {
      marketingChannels.push(...this.appConfig.marketingChannels[this.targetedOrganization]);
    }
    return Array.from(new Set(marketingChannels)).sort();
  }
  getSalesPeople(): User[] {
    const salesPeopleDictionary = this.appConfig.salesPeople as { [organization: string]: User[] };
    if (!this.targetedOrganization) {
      const salesPeople: User[] = [];
      const visitedEmails = new Set<string>();

      for (const organization of this.appConfig.organizations) {
        for (const salesPerson of salesPeopleDictionary[organization]) {
          if (!visitedEmails.has(salesPerson.email)) {
            salesPeople.push(salesPerson);
            visitedEmails.add(salesPerson.email);
          }
        }
      }

      salesPeople.sort((a, b) => {
        if (a.fullName === undefined) {
          return -1;
        }
        if (b.fullName === undefined) {
          return 1;
        }

        return a.fullName.localeCompare(b.fullName);
      });

      return salesPeople;
    }
    return salesPeopleDictionary[this.targetedOrganization] ?? [];
  }
  getExpoParticipants(): User[] {
    return this.appConfig.expoParticipants;
  }
  getBrochures(): string[] {
    const brochures: string[] = [];
    if (!this.targetedOrganization) {
      for (const organization of this.appConfig.organizations) {
        brochures.push(...this.appConfig.brochures[organization]);
      }
    } else {
      brochures.push(...this.appConfig.brochures[this.targetedOrganization]);
    }
    return Array.from(new Set(brochures)).sort();
  }
  getGuides(): string[] {
    const guides: string[] = [];
    if (!this.targetedOrganization) {
      for (const organization of this.appConfig.organizations) {
        guides.push(...this.appConfig.guides[organization]);
      }
    } else {
      guides.push(...this.appConfig.guides[this.targetedOrganization]);
    }
    return Array.from(new Set(guides)).sort();
  }
  getLinks(): string[] {
    const links: string[] = [];
    if (!this.targetedOrganization) {
      for (const organization of this.appConfig.organizations) {
        links.push(...this.appConfig.links[organization]);
      }
    } else {
      links.push(...this.appConfig.links[this.targetedOrganization]);
    }
    return Array.from(new Set(links)).sort();
  }
  getCustomerRequirements(): string[] {
    const customerRequirements: string[] = [];
    if (!this.targetedOrganization) {
      for (const organization of this.appConfig.organizations) {
        customerRequirements.push(...this.appConfig.customerRequirements[organization]);
      }
    } else {
      customerRequirements.push(...this.appConfig.customerRequirements[this.targetedOrganization]);
    }
    return Array.from(new Set(customerRequirements)).sort();
  }
  getCustomerIndustries(): string[] {
    const customerIndustries: string[] = [];
    if (!this.targetedOrganization) {
      for (const organization of this.appConfig.organizations) {
        customerIndustries.push(...this.appConfig.customerIndustries[organization]);
      }
    } else {
      customerIndustries.push(...this.appConfig.customerIndustries[this.targetedOrganization]);
    }
    return Array.from(new Set(customerIndustries)).sort();
  }
  getOrganizations(): string[] {
    return this.appConfig.organizations;
  }
  getCountries(): { iso2AlphaCode: string; name: string }[] {
    return this.appConfig.countries;
  }
}
