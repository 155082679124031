import { DefaultApi, LeadContent, FollowUpPriority, Customer } from 'api-client/api';
import { downloadObjectAsJson } from 'modules/recordLead/utils/DataHandler';
import { ILeadsService } from 'modules/recordLead/core/interfaces';
import { LeadRecordingState } from '../sliceTypes';
import { SimpleCheckboxOptions } from 'modules/common/core/types';

export class LeadsService implements ILeadsService {
  private api: DefaultApi;

  /**
   *
   */
  constructor(private options: { expoName: string; expoId: string }) {
    this.api = new DefaultApi();
  }
  async saveLead(lead: LeadRecordingState): Promise<void> {
    const requestBody = this.convertToRequestBody(lead);
    const organizations = lead.organizations ? this.getKeysWhereValueIsTrue(lead.organizations) : [];
    if (organizations.length < 1) throw new Error('No organizations found to publish the lead to.');
    if (organizations.length >= 2) {
      requestBody.additionalSalesNotes
        ? (requestBody.additionalSalesNotes =
            `Lead was recorded for ${organizations.join(' & ')} \n` + requestBody.additionalSalesNotes)
        : (requestBody.additionalSalesNotes = `Lead was recorded for ${organizations.join(' & ')}`);
    }
    organizations.forEach(async (org: string) => {
      try {
        await this.api.addLead(
          requestBody.identifier as string,
          org as string,
          localStorage.getItem('token') as string,
          requestBody
        );
      } catch (e) {
        downloadObjectAsJson(requestBody, `${requestBody.identifier}.json`);
        throw e;
      }
    });
  }

  private getKeysWhereValueIsTrue(dict: SimpleCheckboxOptions): string[] {
    return Object.keys(dict).filter((key) => dict[key]);
  }

  private convertToRequestBody(lead: LeadRecordingState): LeadContent {
    if (!lead.followUpUser || !lead.ximeaContact || !lead.leadCustomer) {
      throw new Error('Invalid lead. The customer, follow up user, and ximea contact must be specified');
    }
    const brochures = lead.brochures ? this.getKeysWhereValueIsTrue(lead.brochures) : [];
    const manuals = lead.manuals ? this.getKeysWhereValueIsTrue(lead.manuals) : [];
    const links = lead.links ? this.getKeysWhereValueIsTrue(lead.links) : [];

    const industries = lead.customerIndustries ? this.getKeysWhereValueIsTrue(lead.customerIndustries) : [];
    const defaultIndustries = industries.filter((x) => !x.startsWith('#'));

    const requirements = lead.customerRequirements ? this.getKeysWhereValueIsTrue(lead.customerRequirements) : [];
    const defaultRequirements = requirements.filter((x) => !x.startsWith('#'));

    const productInterests = lead.customerProductInterests
      ? this.getKeysWhereValueIsTrue(lead.customerProductInterests)
      : [];
    const defaultProductInterests = productInterests.filter((x) => !x.startsWith('#'));

    const marketingChannels = this.getKeysWhereValueIsTrue(lead.marketingChannels ?? {});
    const defaultMarketingChannels = marketingChannels.filter((x) => !x.startsWith('#'));

    return {
      followUpPriority: (lead.followUpPriority as FollowUpPriority) ?? 'Medium',
      followUpProcess: lead.followUpProcess,
      followUpUser: {
        email: lead.followUpUser.email,
        fullName: lead.followUpUser.fullName,
        defaultOrganization: lead.followUpUser.defaultOrganization,
        allowedOrganizations: lead.followUpUser.allowedOrganizations,
      },
      ximeaContact: {
        email: lead.ximeaContact.email,
        fullName: lead.ximeaContact.fullName,
        defaultOrganization: lead.followUpUser.defaultOrganization,
        allowedOrganizations: lead.followUpUser.allowedOrganizations,
      },
      identifier: lead.sessionId,
      leadCustomer: {
        language: lead.leadCustomer.language,
        address: lead.leadCustomer.address,
        companyName: lead.leadCustomer.companyName,
        contactCardLink: lead.contactCardImageUrl,
        country: lead.leadCustomer.countryCode ?? lead.leadCustomer.addressComponents?.countryName,
        crmAccountNumber: lead.leadCustomerCrmData?.crmAccountNumber,
        crmContactId: lead.leadCustomerCrmData?.crmContactId,
        email: lead.leadCustomer.email,
        fullName: lead.leadCustomer.fullName,
        phone: lead.leadCustomer.phone,
        position: lead.leadCustomer.position,
        salutation: lead.leadCustomer.salutation as Customer.SalutationEnum,
        dataComplete: lead.leadCustomer.dataComplete ?? false,
        firstName: lead.leadCustomer.nameComponents?.firstName,
        lastName: lead.leadCustomer.nameComponents?.lastName,
        city: lead.leadCustomer.addressComponents?.city,
        street: lead.leadCustomer.addressComponents?.street,
        zip: lead.leadCustomer.addressComponents?.zip,
        state: lead.leadCustomer.addressComponents?.state,
        expoCustomerId: lead.conferenceParticipantId,
        countryName: lead.leadCustomer.addressComponents?.countryName || '',
      },
      salesTasks: lead.salesTasks,
      brochures: brochures,

      customIndustry: lead.customIndustry,
      customInterest: lead.customProductInterest,
      customRequirement: lead.customRequirement,
      customMarketingChannel: lead.customMarketingChannel,
      customerProductInterests: defaultProductInterests,
      manuals: manuals,
      industries: defaultIndustries,
      links: links,
      requirements: defaultRequirements,
      marketingChannels: defaultMarketingChannels,
      backofficeTasks: lead.backofficeTasks,
      additionalSalesNotes: lead.salesInformation?.otherNotes,
      salesInformation: lead.salesInformation?.projectInformation,
      leadSource: this.options.expoId,
      leadSourceLongName: this.options.expoName,
    };
  }
}
