import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Lead } from 'modules/common/core/db';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const DraftLeadsAppBar = ({ draftLeads }: { draftLeads: Lead[] | undefined }): JSX.Element => {
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton component={Link} to="/" size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {draftLeads?.length ?? 0} Draft Lead(s)
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
