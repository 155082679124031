import { Button, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState, useAppDispatch, useAppSelector } from 'store/store';
import logo from 'assets/logo.png';
import { PageWithMainControls } from 'modules/common/components';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from 'modules/common/core';
import DraftsIcon from '@mui/icons-material/Drafts';
import {
  leadRecordingSlice,
  recordingStartedSelector,
  reset,
  storeAndReset,
} from 'modules/recordLead/core/leadRecordingSlice';
import { useEffect } from 'react';
import { useConfirm } from 'modules/common';
const LandingPageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  with: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const LogoImage = styled('img')(({ theme }) => ({
  maxHeight: '20vh',
  marginTop: theme.spacing(10),
}));

export const LandingPage = (): JSX.Element => {
  const expoName = useSelector<AppState>((state) => state.appConfig.conferenceData.expoName) as string;
  const recordingStarted = useAppSelector(recordingStartedSelector);
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (recordingStarted) {
      (async () => {
        const result = await confirm({
          title: 'Lead in progress',
          description: 'You have already started recording a lead. Do you want to keep it?',
          confirmationText: 'Yes, keep it',
          showRejection: false,

          cancellationText: 'No, discard it',
        });
        if (result === 'cancel') {
          dispatch(leadRecordingSlice.actions.reset());
          return;
        } else {
          dispatch(storeAndReset(1));
        }
      })();
    }
  }, [recordingStarted]);
  return (
    <PageWithMainControls>
      <LandingPageContainer>
        <LogoImage src={logo} alt="XIMEA Logo" />
        <Typography variant="h5" sx={{ marginBottom: (theme) => theme.spacing(2) }}>
          Lead Processing
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: (theme) => theme.spacing(2) }} textAlign="center">
          {`${expoName}${
            process.env.NODE_ENV !== 'production' || window.location.href.indexOf('staging') >= 0 ? ' (staging)' : ''
          }`}
        </Typography>
        <DraftLeads></DraftLeads>
      </LandingPageContainer>
    </PageWithMainControls>
  );
};

const DraftLeads = () => {
  const draftLeads = useLiveQuery(() => db.leads.count());

  return (
    <Button
      component={Link}
      to="/draft-leads"
      variant="outlined"
      color="primary"
      size="large"
      sx={{
        marginTop: (theme) => theme.spacing(2),
      }}
      startIcon={<DraftsIcon />}
    >
      Draft Leads ({draftLeads ?? 0})
    </Button>
  );
};
