import { Alert, Button, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState, useAppDispatch } from 'store/store';
import { leadRecordingSlice } from 'modules/recordLead/core/leadRecordingSlice';
import React from 'react';
import { ErrorMessage } from 'modules/common/core/types';

export const WarningSnackbar = (): JSX.Element => {
  const error = useSelector<AppState>((state: AppState) => state.leadRecording.error) as ErrorMessage;
  const dispatch = useAppDispatch();
  const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(leadRecordingSlice.actions.setError());
  };
  return (
    <Snackbar
      open={!!error}
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            Ok
          </Button>
        </React.Fragment>
      }
    >
      <Alert
        action={
          <Button
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
            }}
            color="secondary"
            size="small"
            onClick={handleClose}
          >
            Ok
          </Button>
        }
        severity={error && error.type === ErrorMessage.TypeEnum.Error ? 'error' : 'warning'}
      >
        {error?.message}
      </Alert>
    </Snackbar>
  );
};
