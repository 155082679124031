import { QrReader } from 'react-qr-reader';
import { customerDataLoadingSelector, fetchFullData } from 'modules/recordLead/core/leadRecordingSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useServices } from 'modules/recordLead/hooks';
import { useState } from 'react';
import { Box, Tabs, Tab, Button, Paper, Theme, styled, Backdrop, CircularProgress, Typography } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import React from 'react';
import { BrowserMultiFormatReader } from '@zxing/browser';
import { featuresSelector, setScanningLegacyMode } from 'modules/common/core/appConfigSlice';

export const BadgeScanning = (): JSX.Element => {
  const features = useAppSelector(featuresSelector);
  const [value, setValue] = React.useState(features.expoBadgeScanningLegacyMode ? 1 : 0);

  const dispatch = useAppDispatch();
  const services = useServices();
  const processScanResult = (result: string) => {
    if (!services?.conferenceDataService) {
      throw Error('Data parsing service not available');
    }

    dispatch(fetchFullData(result, services));
  };

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(setScanningLegacyMode(newValue === 1));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="Scanning-Options">
          <Tab label="Scanner" {...a11yProps(0)} />
          <Tab label="Image Upload" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel selected={value} index={0}>
        <ScanFromVideo processScanResult={processScanResult} />
      </TabPanel>
      <TabPanel selected={value} index={1}>
        <ScanFromImage processScanResult={processScanResult} />
      </TabPanel>
    </Box>
  );
};

const ScanFromVideo = ({ processScanResult }: ScanComponentProps) => {
  const defaultDelay = 100;
  const [scanDelay, setScanDelay] = useState<number | undefined>(defaultDelay);
  const loading = useAppSelector(customerDataLoadingSelector);

  return (
    <Box sx={{ position: 'relative' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
        open={loading ?? false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <QrReader
        scanDelay={scanDelay}
        containerStyle={{ width: '100%' }}
        constraints={{
          facingMode: 'environment',
        }}
        onResult={(result, error) => {
          if (!!result && !loading) {
            processScanResult(result.getText());
            setScanDelay(undefined);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        videoStyle={{ height: '100%', width: 'auto' }}
        videoContainerStyle={{ height: '100%', width: 'auto' }}
      />
    </Box>
  );
};

interface ScanComponentProps {
  processScanResult: (result: string) => void;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  selected: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, selected, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selected !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {selected === index && <Box sx={{ p: (theme) => theme.spacing(3 * selected, 0) }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const QrCodeUploadContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: 0,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: 'transparent',
}));

const ScanFromImage = ({ processScanResult }: ScanComponentProps) => {
  const loading = useAppSelector(customerDataLoadingSelector);
  const handleUploadClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.item(0);
    if (!selectedFile || loading) {
      return;
    }
    const codeReader = new BrowserMultiFormatReader();
    const tempUrl = URL.createObjectURL(selectedFile);
    const result = await codeReader.decodeFromImageUrl(tempUrl);
    processScanResult(result.getText());
  };

  return (
    <>
      <QrCodeUploadContainer variant="outlined">
        <input
          onChange={handleUploadClick}
          accept="image/*"
          capture="environment"
          style={{ display: 'none' }}
          id="qr-code-upload"
          disabled={loading}
          type="file"
        />
        <label style={{ width: '100%', height: '100%' }} htmlFor="qr-code-upload">
          <Button
            sx={{
              padding: (theme: Theme) => theme.spacing(0.5),
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            component="span"
          >
            <span
              style={{
                width: '35px',
                height: '35px',
                marginBottom: '0.3rem',
              }}
            >
              {loading ? <CircularProgress /> : <AddAPhotoIcon sx={{ width: '100%', height: '100%' }} />}
            </span>
            <Typography variant="button" component="span" sx={{ padding: (theme) => theme.spacing(0.3, 0) }}>
              {loading ? 'Fetching Customer Data...' : 'Upload QR Code'}
            </Typography>
          </Button>
        </label>
      </QrCodeUploadContainer>
    </>
  );
};
